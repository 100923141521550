import { useString as s } from "../../../../components/StringProvider";

const MessageStats = ({ message }) => {
  return (
    <div>
      <code>
        {s(
          "aiConversationDetails.page.messageStats.promptTokens",
          "Prompt Tokens: {tokens}",
          { tokens: message.promptTokens }
        )}
        &nbsp;
        {s(
          "aiConversationDetails.page.messageStats.completionTokens",
          "Completion Tokens: {tokens}",
          { tokens: message.completionTokens }
        )}
        &nbsp;
        {s(
          "aiConversationDetails.page.messageStats.completionTokens",
          "Response Time {time}ms",
          { time: message.responseTime }
        )}
      </code>
    </div>
  );
};

export default MessageStats;
