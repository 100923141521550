import React, { useContext, useState } from "react";
import styled from "styled-components";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { saveDiscoveryROI } from "../../../store/actions/discoveries";
import Heading from "../../../components/Heading";
import Text from "../../../components/Text";
import { useString as s } from "../../../components/StringProvider";
import {
  CurrencyInput,
  InputGroup,
  SliderInput
} from "../../../components/Input";
import Form, { FormItem } from "../../../components/Form";
import { SettingsContext } from "../../../components/SettingsProvider";
import NewButton from "../../../components/NewButton";
import {
  hasPermission,
  isDiscoveryROIComplete,
  selectDiscovery,
  selectRequestState
} from "../../../store/reducers";
import Permissions from "../../../utils/permissions";
import { formatCurrencyString } from "../../../utils/formatting";
import { CommentsButton } from "../../../components/Discovery/Comments";
import { useMobileMediaQuery } from "../../../components/Responsive";
import ROIFormHeader from "./ROIFormHeader";
import { DiscoveryFooter } from "../../../components/Discovery";
import actionTypes from "../../../store/actionTypes";
import useLoadingState from "../../../utils/use-loading-state";
import { message } from "antd";
import ListOfCurrencyValues from "./ListOfCurrencyValues";
import ListOfPercentageValues from "./ListOfPercentageValues";
import Toggle from "../../../components/Toggle";
import { themeProp } from "../../../theme";
import { UserPilotEventNames } from "../../../utils/user-pilot-util";

const CurrencyInputCommentBox = ({ topic, ...props }) => {
  return (
    <>
      <CurrencyInput {...props} />
      <div className={"visible-on-hover"}>
        <CommentsButton
          topic={topic}
          mobilePlacement={"bottom"}
          eventName={UserPilotEventNames.ADD_NEW_COMMENT_ROI}
        />
      </div>
    </>
  );
};

const ROIForm = ({
  discovery,
  canUpdateROIValues,
  onSubmit,
  saveDiscoveryROI,
  loadingState,
  isComplete
}) => {
  const isMobile = useMobileMediaQuery();
  const [form] = Form.useForm();
  const { roi = {} } = discovery;
  const { getSetting, settings } = useContext(SettingsContext);
  const [submitted, setSubmitted] = useState(isComplete);
  const [annualAdjustmentMode, setAnnualAdjustmentMode] = useState(
    roi?.annualAdjustmentMode
  );
  const defaultEvaluationPeriod = getSetting(
    "discovery.roi.evaluationPeriod.default",
    3
  );
  const [evaluationPeriod, setEvaluationPeriod] = useState(
    discovery?.roi?.evaluationPeriod || defaultEvaluationPeriod
  );
  const [annualInvestment, setAnnualInvestment] = useState(
    discovery?.roi?.annualInvestment
  );
  const [upfrontInvestment, setUpfrontInvestment] = useState(
    discovery?.roi?.upfrontInvestment
  );

  const discoverySettings = {
    ...settings,
    currency: discovery.currency
  };
  const header = s("discovery.roi.page.header", "Project Assumptions");
  const subHeader = s(
    "discovery.roi.page.subheader",
    "Please enter the details to calculate the Return on Investment"
  );
  const annualAdjustmentModeLabel = s(
    "discovery.roi.form.annualAdjustmentMode.label",
    `Advanced mode`
  );
  const annualInvestmentLabel = s(
    "discovery.roi.form.title1",
    `Annual Investment`
  );
  const annualInvestmentTooltip = s(
    "discovery.roi.form.input1",
    "The annual cost of the new solution e.g. the SaaS ARR plus any additional annual fees"
  );
  const annualInvestmentValidation = s(
    "discovery.roi.form.annualInvestment.validation",
    "Annual Investment must be greater than zero"
  );
  const upfrontInvestmentLabel = s(
    "discovery.roi.form.title2",
    `One-Off Investment`
  );
  const upfrontInvestmentTooltip = s(
    "discovery.roi.form.input2",
    "The sum of any one-off costs e.g. implementation services cost"
  );
  const upfrontInvestmentValidation = s(
    "discovery.roi.form.upfrontInvestment.validation",
    "One-Off Investment must be greater than zero"
  );
  const benefitAdjustmentLabel = s(
    "discovery.roi.form.benefitAdjustment.label",
    `Benefit Adjustment`
  );
  const benefitAdjustmentTooltip = s(
    "discovery.roi.form.benefitAdjustment.tooltip",
    "Adjust the benefit for each year up or down. The app applies this factor after the Time to Benefits Start and Time to Full Adoption have been applied."
  );
  const benefitAdjustmentValidation = s(
    "discovery.roi.form.benefitAdjustment.validation",
    "Benefit adjustment must be greater than zero"
  );
  const failedROISave = s(
    "discovery.roi.form.messages.failedROISave",
    "Failed to save ROI"
  );
  const evaluationPeriodMin = getSetting(
    "discovery.roi.evaluationPeriod.min",
    1
  );
  const evaluationPeriodLabel = s(
    "discovery.roi.form.title3",
    `Evaluation Period, years`
  );
  const evaluationPeriodTooltip = s(
    "discovery.roi.form.input3",
    "How many years should we evaluate the ROI over e.g. the contract length"
  );
  const evaluationPeriodMax = getSetting(
    "discovery.roi.evaluationPeriod.max",
    5
  );
  const implementationLengthMin = getSetting(
    "discovery.roi.implementationLength.min",
    1
  );
  const implementationLengthMax = getSetting(
    "discovery.roi.implementationLength.max",
    12
  );
  const adoptionTimeMin = getSetting("discovery.roi.adoptionTime.min", 1);
  const adoptionTimeMax = getSetting("discovery.roi.adoptionTime.max", 12);
  const adoptionTimeTitle = s(
    "discovery.roi.form.adoptionTime.title",
    `Time to Full Adoption, months`
  );
  const adoptionTimeTooltip = s(
    "discovery.roi.form.adoptionTime.tooltip",
    `Once the benefits start accruing (i.e. the solution has been implemented), how many months will it take to ramp up to full adoption and start realizing the full benefit. The app will model a linear ramp-up of the benefits during this adoption period.`
  );
  const adoptionTimeValidation = s(
    "discovery.roi.form.adoptionTime.validation",
    `Time to Full Adoption cannot be negative or alphabetical`
  );
  const adoptionTimeEnabled =
    getSetting("discovery.roi.adoptionTime.min", undefined) !== undefined;
  const calculate = s("discovery.roi.form.calculate", "Calculate");
  const sectionHeader1 = s("discovery.roi.page.section.header1", "Investment");
  const sectionHeader2 = s("discovery.roi.page.section.header2", "Benefits");
  const benefitsStartLabel = s(
    "discovery.roi.form.title4",
    `Time to Benefits Start, months`
  );
  const benefitsStartTooltip = s(
    "discovery.roi.form.input4",
    "How many months before the benefits will start accruing e.g. how long will it take to implement the solution"
  );

  const evaluationPeriodMarks = Array.from(
    Array(evaluationPeriodMax - evaluationPeriodMin + 1).keys()
  )
    .map((i) => i + evaluationPeriodMin)
    .reduce((map, n) => {
      map[n] = n;
      return map;
    }, {});

  const implementationLengthMarks = Array.from(
    Array(implementationLengthMax - implementationLengthMin + 1).keys()
  )
    .map((i) => i + implementationLengthMin)
    .reduce((map, n) => {
      map[n] = n;
      return map;
    }, {});

  const adoptionTimeMarks = Array.from(
    Array(adoptionTimeMax - adoptionTimeMin + 1).keys()
  )
    .map((i) => i + adoptionTimeMin)
    .reduce((map, n) => {
      map[n] = n;
      return map;
    }, {});

  useLoadingState(
    loadingState,
    () => {
      onSubmit();
    },
    () => {
      message.error(failedROISave);
    }
  );

  const handleCalculate = (changes) => {
    if (
      changes["upfrontInvestment"] === null ||
      changes["upfrontInvestment"] === undefined
    ) {
      changes["upfrontInvestment"] = 0;
    }

    /*
      An annualInvestment value must be provided.
      When annualAdjustmentMode = true it is allowed to
      have zero values (the current behaviour which may need
      revisiting).
     */
    if (
      (changes["annualInvestment"] === null ||
        changes["annualInvestment"] === undefined) &&
      (!changes["annualInvestmentPerYear"] ||
        changes["annualInvestmentPerYear"].length === 0)
    ) {
      form.setFields([
        {
          name: annualAdjustmentMode
            ? "annualInvestmentPerYear"
            : "annualInvestment",
          errors: [annualInvestmentValidation]
        }
      ]);
    } else {
      saveDiscoveryROI({ discoveryId: discovery["_id"], changes });
      setSubmitted(true);
    }
  };

  const handleError = (...props) => {
    console.log("error", ...props);
  };

  const handleChange = () => {
    if (submitted) {
      setSubmitted(false);
    }
  };

  const onFieldsChange = (changedFields) => {
    const evaluationPeriodField = changedFields.find(
      (f) => f.name[0] === "evaluationPeriod"
    );
    const annualInvestmentField = changedFields.find(
      (f) => f.name[0] === "annualInvestment"
    );
    const upfrontInvestmentField = changedFields.find(
      (f) => f.name[0] === "upfrontInvestment"
    );

    if (evaluationPeriodField) {
      setEvaluationPeriod(evaluationPeriodField.value);
    }

    if (upfrontInvestmentField) {
      setUpfrontInvestment(upfrontInvestmentField.value);
    }

    if (annualInvestmentField) {
      setAnnualInvestment(annualInvestmentField.value);
    }
  };

  const onAnnualAdjustmentMode = (value) => {
    setAnnualAdjustmentMode(value);
    handleChange();
  };

  return (
    <ROIFormContainer
      form={form}
      className={isMobile ? "mobile" : undefined}
      name="roiForm"
      onFieldsChange={onFieldsChange}
      initialValues={{
        evaluationPeriod: getSetting(
          "discovery.roi.evaluationPeriod.default",
          3
        ),
        implementationLength: getSetting(
          "discovery.roi.implementationLength.default",
          6
        ),
        adoptionTime: getSetting("discovery.roi.adoptionTime.default", 1),
        ...discovery.roi
      }}
      onFinish={handleCalculate}
      onFinishFailed={handleError}
      onChange={handleChange}
    >
      <ROIFormHeader className={isMobile ? "mobile" : undefined}>
        <HeadingContainer>
          <Heading
            className="up-roi-project-assumptions"
            level={isMobile ? "h3" : "h2"}
          >
            {header}
          </Heading>
          <Text variant={isMobile ? "bodyMobile" : "body"}>{subHeader}</Text>
          <ToggleContainer className="up-roi-annual-adjustment-mode">
            <FormItem
              label={annualAdjustmentModeLabel}
              name={"annualAdjustmentMode"}
              valuePropName={"checked"}
              colon={false}
            >
              <Toggle
                onChange={onAnnualAdjustmentMode}
                disabled={!canUpdateROIValues}
                size={"medium"}
              />
            </FormItem>
          </ToggleContainer>
        </HeadingContainer>
      </ROIFormHeader>
      <ROISectionContainer>
        <ROISection>
          <Heading className="up-roi-project-assumptions" level={"h3"}>
            {sectionHeader1}
          </Heading>
          <FormItemContainer>
            <InputGroup
              title={evaluationPeriodLabel}
              help={evaluationPeriodTooltip}
              className={"input-group-container"}
            >
              <FormItem
                name="evaluationPeriod"
                rules={[
                  {
                    required: true,
                    type: "integer",
                    message:
                      "Evaluation Period cannot be negative or alphabetical",
                    min: evaluationPeriodMin,
                    max: evaluationPeriodMax
                  }
                ]}
                className="slider"
              >
                <SliderInput
                  min={evaluationPeriodMin}
                  max={evaluationPeriodMax}
                  onChange={handleChange}
                  marks={isMobile ? evaluationPeriodMarks : undefined}
                  disabled={!canUpdateROIValues}
                  size={"medium"}
                />
              </FormItem>
            </InputGroup>
            <HoverArea>
              <InputGroup
                title={annualInvestmentLabel}
                help={annualInvestmentTooltip}
              >
                <FormItem
                  name="annualInvestment"
                  hidden={annualAdjustmentMode}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message: annualInvestmentValidation
                    }
                  ]}
                >
                  <CurrencyInputCommentBox
                    topic={`/discoveries/${discovery._id}/roi/annualInvestment`}
                    min="0"
                    placeholder={formatCurrencyString({
                      value: 0,
                      settings: discoverySettings
                    })}
                    currency={discovery.currency}
                    disabled={!canUpdateROIValues}
                  />
                </FormItem>
                <FormItem
                  name="annualInvestmentPerYear"
                  hidden={!annualAdjustmentMode}
                  rules={[
                    {
                      type: "array",
                      message: annualInvestmentValidation
                    }
                  ]}
                >
                  <ListOfCurrencyValues
                    currency={discovery.currency}
                    length={evaluationPeriod}
                    topic={`/discoveries/${discovery._id}/roi/annualInvestment`}
                    startingValue={annualInvestment}
                    disabled={!canUpdateROIValues}
                    annualAdjustmentMode={annualAdjustmentMode}
                  />
                </FormItem>
              </InputGroup>
            </HoverArea>
            <HoverArea>
              <InputGroup
                title={upfrontInvestmentLabel}
                help={upfrontInvestmentTooltip}
              >
                <FormItem
                  name="upfrontInvestment"
                  hidden={annualAdjustmentMode}
                  rules={[
                    {
                      type: "number",
                      min: 0,
                      message:
                        "Upfront Investment cannot be negative or alphabetical"
                    }
                  ]}
                >
                  <CurrencyInputCommentBox
                    placeholder={formatCurrencyString({
                      value: 0,
                      settings: discoverySettings
                    })}
                    currency={discovery.currency}
                    disabled={!canUpdateROIValues}
                    topic={`/discoveries/${discovery._id}/roi/upfrontInvestment`}
                  />
                </FormItem>
                <FormItem
                  name="upfrontInvestmentPerYear"
                  hidden={!annualAdjustmentMode}
                  rules={[
                    {
                      type: "array",
                      message: upfrontInvestmentValidation
                    }
                  ]}
                >
                  <ListOfCurrencyValues
                    currency={discovery.currency}
                    length={evaluationPeriod}
                    topic={`/discoveries/${discovery._id}/roi/upfrontInvestment`}
                    startingValue={upfrontInvestment}
                    disabled={!canUpdateROIValues}
                    annualAdjustmentMode={annualAdjustmentMode}
                  />
                </FormItem>
              </InputGroup>
            </HoverArea>
          </FormItemContainer>
        </ROISection>
        <ROISection>
          <Heading className="up-roi-project-assumptions" level={"h3"}>
            {sectionHeader2}
          </Heading>
          <FormItemContainer>
            <InputGroup
              title={benefitsStartLabel}
              help={benefitsStartTooltip}
              className={"input-group-container"}
            >
              <FormItem
                name="implementationLength"
                rules={[
                  {
                    required: true,
                    type: "integer",
                    message:
                      "Implementation Length cannot be negative or alphabetical",
                    min: implementationLengthMin,
                    max: implementationLengthMax
                  }
                ]}
              >
                <SliderInput
                  min={implementationLengthMin}
                  max={implementationLengthMax}
                  disabled={!canUpdateROIValues}
                  onChange={handleChange}
                  marks={isMobile ? implementationLengthMarks : undefined}
                  size={"medium"}
                />
              </FormItem>
            </InputGroup>
            {adoptionTimeEnabled && (
              <InputGroup
                title={adoptionTimeTitle}
                help={adoptionTimeTooltip}
                className={"input-group-container"}
              >
                <FormItem
                  name="adoptionTime"
                  rules={[
                    {
                      type: "integer",
                      message: adoptionTimeValidation,
                      min: implementationLengthMin,
                      max: implementationLengthMax
                    }
                  ]}
                >
                  <SliderInput
                    min={adoptionTimeMin}
                    max={adoptionTimeMax}
                    disabled={!canUpdateROIValues}
                    onChange={handleChange}
                    marks={isMobile ? adoptionTimeMarks : undefined}
                    size={"medium"}
                  />
                </FormItem>
              </InputGroup>
            )}
            <InputGroup
              title={benefitAdjustmentLabel}
              help={benefitAdjustmentTooltip}
              hidden={!annualAdjustmentMode}
              className={"input-group-container"}
            >
              <FormItem
                name="benefitAdjustmentPerYear"
                hidden={!annualAdjustmentMode}
                rules={[
                  {
                    required: true,
                    type: "array",
                    min: evaluationPeriod,
                    message: benefitAdjustmentValidation
                  }
                ]}
              >
                <ListOfPercentageValues
                  currency={discovery.currency}
                  length={evaluationPeriod}
                  disabled={!canUpdateROIValues}
                />
              </FormItem>
            </InputGroup>
          </FormItemContainer>
        </ROISection>
      </ROISectionContainer>
      <DiscoveryFooter className={isMobile ? "mobile" : undefined}>
        {!submitted && (
          <NewButton
            block={isMobile}
            type={"submit"}
            data-cy={"calculate-button"}
            className={"up-roi-calculate-button"}
          >
            {calculate}
          </NewButton>
        )}
      </DiscoveryFooter>
    </ROIFormContainer>
  );
};

const ROIFormContainer = styled(Form)`
  display: flex;
  flex-direction: column;

  & .ant-form-item-label > label {
    font-size: ${themeProp(`typography.body.fontSize`)};
  }

  & .visible-on-hover {
    display: inline-block;
    margin-left: 20px;
    visibility: hidden;

    & .open-comments-button-open,
    & .open-comments-button-has-comments,
    & .ant-dropdown-open {
      visibility: visible;
    }
  }

  &.mobile .visible-on-hover {
    visibility: visible;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 577px) {
    width: 100%;
    align-items: center;
    text-align: center;
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 32px;

  .ant-form-item-row {
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    gap: 8px;
  }

  .ant-form-item-control-input {
    display: flex;
    align-items: center;
  }

  .ant-form-item-label {
    display: flex;
    justify-content: center;
  }
`;

const ROISectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-top: 12px;
`;

const ROISection = styled.div`
  max-width: fit-content;
  background-color: ${themeProp("palette.surface")};
  padding: 32px;
  border-radius: 6px;
  border: 1px solid ${themeProp("palette.gray2")};
  display: flex;
  flex-direction: column;
  gap: 20px;

  .ant-form-item-control-input-content {
    & > * {
      flex-wrap: wrap;
    }
  }

  .ant-form-item-control-input {
    min-height: 0px;

    @media screen and (max-width: 726px) {
      min-height: 32px;
    }
  }

  .slider-input-container {
    margin-top: -20px;

    @media screen and (max-width: 726px) {
      margin-top: 0px;
      width: 100%;
      gap: 16px;
    }
  }

  .slider-input-wrapper {
    @media screen and (max-width: 726px) {
      margin-left: 0px;
    }
  }

  .input-group-container span {
    z-index: 1;
  }

  h3 {
    margin: 0;
  }

  @media screen and (max-width: 986px) {
    max-width: 100%;
  }
`;

const HoverArea = styled.div`
  align-self: flex-start;

  &:hover .visible-on-hover,
  &:focus-within .visible-on-hover {
    visibility: visible;
  }
`;

const FormItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > * {
    padding-bottom: 0;
    margin-bottom: 0;
    min-width: 834px;
    max-width: 100%;

    @media screen and (max-width: 986px) {
      min-width: 100%;
    }

    & > * {
      padding-bottom: 0;
      margin-bottom: 0;
    }
  }

  span {
    font-weight: ${themeProp(`typography.h5Small.fontWeight`)};
  }
`;

const mapStateToProps = (state) => ({
  discovery: selectDiscovery(state),
  canUpdateROIValues: hasPermission(state, Permissions.UPDATE_ROI_VALUES),
  loadingState: selectRequestState(
    state,
    actionTypes.DISCOVERY_SAVE_ROI_REQUEST
  ),
  isComplete: isDiscoveryROIComplete(state)
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      saveDiscoveryROI
    },
    dispatch
  );

export default compose(connect(mapStateToProps, mapDispatchToProps))(ROIForm);
