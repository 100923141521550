import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Divider } from "antd";
import styled, { css } from "styled-components";
import { themeProp } from "../../../../theme";
import PropTypes from "prop-types";
import {
  hasPermission,
  selectDiscoveryCurrency,
  selectDiscoveryDelayTimeline,
  selectDiscoveryScenario,
  selectRequestData,
  selectDiscoveryShowAdditionalBenefits
} from "../../../../store/reducers";
import { compose } from "redux";
import { connect } from "react-redux";
import EstimationScenario from "../../../ROI/components/EstimationScenario";
import { Phases } from "../../../../utils/phases";
import actionTypes from "../../../../store/actionTypes";
import DelayTimeline from "../../../ROI/components/DelayTimeline";
import { getValue } from "../../value-hypothesis-util";
import { useMobileMediaQuery } from "../../../../components/Responsive";
import KPIColumn from "./KPIColumn";
import TotalColumn from "./TotalColumn";
import DelayTimelineOptions from "../DelayTimelineOptions";
import Permissions from "../../../../utils/permissions";
import ShowAdditionalBenefits from "../ShowAdditionalBenefits";

const CostOfStatusQuoChart = ({
  kpis,
  reportMode,
  currency,
  scenario,
  delayTimeline,
  template,
  printMode,
  showChartControls,
  canSeeLeadGeneration,
  showAdditionalBenefits
}) => {
  const isMobile = useMobileMediaQuery();
  const [height, setHeight] = useState(600);
  const [currencyHeight, setCurrencyHeight] = useState(0);
  const percentageBoxHeight = 48;
  const boxRef = useRef();

  const sortKPIs = (a, b) => {
    return a.definition.name.localeCompare(b.definition.name);
  };
  const filteredKPIs = showAdditionalBenefits
    ? kpis
    : kpis.filter((kpi) => kpi.hasImpactValue);

  const activeKPIs = filteredKPIs
    .reduce((list, kpi) => (kpi.selected ? [...list, kpi] : list), [])
    .sort(sortKPIs);
  const currencyKPIs = activeKPIs
    .reduce(
      (list, kpi) =>
        kpi.hasImpactValue && !kpi.definition.oneTimeBenefit
          ? [...list, kpi]
          : list,
      []
    )
    .sort(sortKPIs);
  const percentageKPIs = activeKPIs
    .reduce(
      (list, kpi) =>
        !kpi.hasImpactValue &&
        kpi.hasImpactPercentage &&
        !kpi.definition.oneTimeBenefit
          ? [...list, kpi]
          : list,
      []
    )
    .sort(sortKPIs);
  const totalAmount = currencyKPIs.reduce(
    (sum, kpi) => getValue({ kpi, scenario, delayTimeline }) + sum,
    0
  );

  useLayoutEffect(() => {
    if (boxRef.current) {
      setHeight(boxRef.current.offsetHeight);
    }
  }, []);

  useEffect(() => {
    setCurrencyHeight(
      height - 108 - 20 - percentageBoxHeight * percentageKPIs.length
    );
  }, [height, kpis]);

  const currencyHeights = [];

  for (const currencyKPI of currencyKPIs) {
    currencyHeights.push(
      Math.ceil(
        (currencyHeight *
          getValue({ kpi: currencyKPI, scenario, delayTimeline })) /
          totalAmount
      )
    );
  }

  if (showChartControls === undefined) {
    showChartControls = !reportMode || (reportMode && template !== Phases.ROI);
    if (canSeeLeadGeneration) {
      showChartControls = false;
    }
  }

  return (
    <ChartContainer
      data-cy={"cost-of-status-quo-chart"}
      className={"cost-of-status-quo"}
    >
      {showChartControls && (
        <>
          <SlidersContainer isMobile={isMobile} printMode={printMode}>
            <DelayTimeline
              reportMode={reportMode}
              fixedWidth={printMode ? "400px" : "350px"}
            />
            <EstimationScenario
              reportMode={reportMode}
              fixedWidth={printMode ? "400px" : "350px"}
            />
            <ShowAdditionalBenefits
              reportMode={reportMode}
              printMode={printMode}
              fixedWidth={"272px"}
            />
          </SlidersContainer>
          <Divider />
        </>
      )}
      <ChartBox
        ref={boxRef}
        reportMode={reportMode}
        printMode={printMode}
        currencyHeights={currencyHeights}
        numberOfPercentageKPIs={percentageKPIs.length}
      >
        {currencyKPIs.map((discoveryKPI, index) => (
          <KPIColumn
            discoveryKPI={discoveryKPI}
            key={discoveryKPI.kpiCode}
            index={index}
            currency={currency}
            delayTimeline={delayTimeline}
            scenario={scenario}
          />
        ))}
        {percentageKPIs.map((discoveryKPI, index) => (
          <KPIColumn
            discoveryKPI={discoveryKPI}
            key={discoveryKPI.kpiCode}
            index={index + currencyKPIs.length}
            currency={currency}
            delayTimeline={DelayTimelineOptions.YEAR}
            scenario={scenario}
          />
        ))}
        <TotalColumn
          numberOfCurrencyKPIs={currencyKPIs.length}
          numberOfNonCurrencyKPIs={percentageKPIs.length}
          currency={currency}
          totalAmount={totalAmount}
        />
      </ChartBox>
    </ChartContainer>
  );
};

const SlidersContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding-top: 8px;

  ${({ isMobile }) =>
    !isMobile &&
    css`
      flex-direction: column;

      @media screen and (min-width: 1160px) {
        flex-direction: row;
      }
    `}

  ${({ printMode }) =>
    printMode &&
    css`
      flex-direction: row;
    `}
`;

const ChartBox = styled.div`
  min-width: 100%;
  display: grid;
  ${({ printMode }) => (!printMode ? "min-height: 550px;" : "")};
  grid-template-rows:
    54px ${({ currencyHeights }) =>
      currencyHeights.map((v) => `minmax(3px, ${v}px)`).join(" ")}
    ${({ numberOfPercentageKPIs }) =>
      numberOfPercentageKPIs
        ? `repeat(${numberOfPercentageKPIs},48px)`
        : undefined}
    54px;
  grid-template-columns:
    repeat(
      ${({ numberOfPercentageKPIs, currencyHeights }) =>
        numberOfPercentageKPIs + currencyHeights.length},
      minmax(80px, 1fr)
    )
    184px;
  column-gap: 8px;
  overflow-x: scroll;
  flex-grow: 1;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
    height: 7px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: ${themeProp("palette.gray2")};
  }
`;

ChartBox.propTypes = {
  reportMode: PropTypes.bool,
  printMode: PropTypes.bool
};

ChartBox.defaultProps = {
  reportMode: false
};

const ChartContainer = styled.div`
  width: 100%;
  box-shadow: 0 0 4px rgba(5, 37, 67, 0.15);
  background-color: ${themeProp("palette.surface")};
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

CostOfStatusQuoChart.propTypes = {
  reportMode: PropTypes.bool,
  showChartControls: PropTypes.bool,
  printMode: PropTypes.bool
};

CostOfStatusQuoChart.defaultProps = {
  reportMode: false
};

const mapStateToProps = (state, props) => ({
  scenario: selectDiscoveryScenario(state),
  delayTimeline: selectDiscoveryDelayTimeline({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_DELAY_TIMELINE_REQUEST
  }),
  currency: selectDiscoveryCurrency(state),
  template: selectRequestData(
    state,
    actionTypes.DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS
  ),
  canSeeLeadGeneration: hasPermission(state, Permissions.SEE_LEAD_GENERATION),
  showAdditionalBenefits: selectDiscoveryShowAdditionalBenefits({
    state,
    reportMode: props.reportMode,
    actionType: actionTypes.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST
  })
});

export default compose(connect(mapStateToProps))(CostOfStatusQuoChart);
