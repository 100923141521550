import React from "react";
import styled, { css } from "styled-components";
import { InputNumber as ANTInputNumber } from "antd";
import { themeProp } from "../../theme";
import PropTypes from "prop-types";
import { useMobileMediaQuery } from "../Responsive";

const NumberInput = React.forwardRef(
  (
    { value, size, formatter, parser, onChange, onCommit, ...props },
    inputRef
  ) => {
    const isMobile = useMobileMediaQuery();

    const handleBlur = () => {
      if (onCommit) {
        onCommit(value);
      }
    };
    const handleOnChange = (v) => {
      if (onChange) {
        onChange(v);
      }
    };

    return (
      <NumberInputContainer
        className={isMobile ? "mobile" : undefined}
        formatter={formatter}
        parser={parser}
        value={value}
        size={size}
        onBlur={handleBlur}
        onChange={handleOnChange}
        onPressEnter={(e) => {
          e.target.blur();
          e.preventDefault();
        }}
        ref={inputRef}
        {...props}
      />
    );
  }
);

const NumberInputContainer = styled(ANTInputNumber).attrs(() => ({
  bordered: false
}))`
  width: initial;
  background: transparent;
  width: 131px;
  border-radius: 0;

  &:hover {
    background-color: ${themeProp("palette.surface")};
  }

  .ant-input-number-input {
    font-weight: bold;
    color: ${themeProp("palette.secondary")};
    transition: background 0;
    border-bottom: 1px solid ${themeProp("palette.secondary")};
    font-size: ${themeProp("typography.body.fontSize")};
    transition: border-color 0.2s ease-out;
    border-radius: 0;

    ${({ size }) =>
      size === "large"
        ? css`
            height: 54px !important;
            padding: 0 12px;
          `
        : css`
            height: 40px !important;
            padding: 0 8px;
          `}

    &::placeholder {
      color: ${themeProp("palette.gray3")};
      font-size: ${themeProp("typography.body.fontSize")};
      line-height: ${themeProp("typography.body.lineHeight")};
      font-weight: ${themeProp("typography.body.fontWeight")};
    }

    /* Chrome, Safari, Edge, Opera */
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &:hover {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
    }
    &:focus {
      border-bottom: 1px solid ${themeProp("palette.secondary")};
      background: ${themeProp("palette.surface")};
    }
  }

  .ant-input-number-handler-wrap {
    display: none;
  }
`;

NumberInput.propType = {
  value: PropTypes.number.isRequired,
  formatter: PropTypes.func.isRequired,
  parser: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  onCommit: PropTypes.func,
  size: PropTypes.oneOf(["medium", "large"])
};

NumberInput.defaultProps = {
  formatter: (value) => value,
  size: "large"
};

export default NumberInput;
