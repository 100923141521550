import discoveriesService from "../../services/discoveries.service";
import types from "../actionTypes";
import { v4 as uuidv4 } from "uuid";
import reportsService from "../../services/reports.service";

export const fetchDiscovery = (discoveryId) => (dispatch) => {
  dispatch({
    type: types.DISCOVERY_FETCH_REQUEST
  });

  return discoveriesService
    .getDiscovery({ discoveryId })
    .then((discovery) => {
      dispatch({
        type: types.DISCOVERY_FETCH_SUCCESS,
        payload: { discovery }
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.DISCOVERY_FETCH_FAILURE,
        payload: { error }
      });
    });
};

export const fetchDiscoveryByReportId =
  ({ reportId }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_FETCH_REQUEST
    });

    return discoveriesService
      .getDiscoveryByReportId({ reportId })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_FETCH_SUCCESS,
          payload: { discovery }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_FETCH_FAILURE,
          payload: { error }
        });
      });
  };

export const refreshDiscovery =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_REFRESH_REQUEST
    });

    return discoveriesService
      .getDiscovery({ discoveryId })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_REFRESH_SUCCESS,
          payload: { discovery }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_REFRESH_FAILURE,
          payload: { error }
        });
      });
  };

export const saveDiscoveryQuestion =
  ({ discoveryId, questionNumber, answer }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_QUESTION_REQUEST
    });

    return discoveriesService
      .saveDiscoveryQuestion({ discoveryId, questionNumber, answer })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_SAVE_QUESTION_SUCCESS,
          payload: { discovery },
          parameters: { questionNumber, answer }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_QUESTION_FAILURE,
          error
        });
      });
  };

export const saveDiscoveryQuestionNextDisabled =
  ({ disabled }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_QUESTION_SUBMIT_DISABLED_SUCCESS,
      payload: disabled
    });
  };

export const setCanvasClicked =
  ({ clicked }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_CANVAS_CLICKED_SUCCESS,
      payload: clicked
    });
  };

export const saveDiscoveryCheckpoint =
  ({ discoveryId, checkpoint, lastViewedPage }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_CHECKPOINT_REQUEST
    });

    return discoveriesService
      .saveDiscoveryCheckpoint({ discoveryId, checkpoint, lastViewedPage })
      .then((result) => {
        dispatch({
          type: types.DISCOVERY_SAVE_CHECKPOINT_SUCCESS,
          payload: {
            discoveryId,
            checkpoint: result.checkpoint,
            phase: result.phase,
            lastViewedPage: result.lastViewedPage
          }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_CHECKPOINT_FAILURE,
          payload: { error }
        });
      });
  };

export const saveDiscoveryScenario =
  ({ discoveryId, scenario }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_SCENARIO_REQUEST
    });

    return discoveriesService
      .saveDiscoveryScenario({ discoveryId, scenario })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SAVE_SCENARIO_SUCCESS,
          payload
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_SCENARIO_FAILURE,
          payload: { error }
        });
      });
  };

export const setDiscoveryScenario =
  ({ scenario }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SET_SCENARIO_REQUEST
    });

    dispatch({
      type: types.DISCOVERY_SET_SCENARIO_SUCCESS,
      payload: scenario
    });
  };

export const saveDiscoveryDelayTimeline =
  ({ discoveryId, delayTimeline }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_DELAY_TIMELINE_REQUEST
    });

    return discoveriesService
      .saveDiscoveryDelayTimeline({ discoveryId, delayTimeline })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SAVE_DELAY_TIMELINE_SUCCESS,
          payload
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_DELAY_TIMELINE_FAILURE,
          payload: { error }
        });
      });
  };

export const setDiscoveryDelayTimeline =
  ({ delayTimeline }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SET_DELAY_TIMELINE_REQUEST
    });

    dispatch({
      type: types.DISCOVERY_SET_DELAY_TIMELINE_SUCCESS,
      payload: delayTimeline
    });
  };

export const setDiscoveryShowAdditionalBenefits =
  ({ showAdditionalBenefits }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST
    });

    dispatch({
      type: types.DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_SUCCESS,
      payload: showAdditionalBenefits
    });
  };

export const saveDiscoveryROIBenefits =
  ({ discoveryId, benefits }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_ROI_BENEFITS_REQUEST
    });

    return discoveriesService
      .saveDiscoveryROIBenefits({ discoveryId, benefits })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SAVE_ROI_BENEFITS_SUCCESS,
          payload
        });
        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_ROI_BENEFITS_FAILURE,
          payload: { error }
        });
      });
  };

export const saveDiscoveryChallenge =
  ({ discoveryId, challengeCode, changes }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_CHALLENGE_REQUEST
    });

    return discoveriesService
      .saveDiscoveryChallenge({ discoveryId, challengeCode, changes })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_SAVE_CHALLENGE_SUCCESS,
          payload: { discovery }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_CHALLENGE_FAILURE,
          error
        });
      });
  };

export const addDiscoveryChallengeFromLibrary =
  ({ discoveryId, challengeCode, position }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST,
      parameters: { discoveryId, challengeCode, position }
    });

    return discoveriesService
      .addDiscoveryChallengeFromLibrary({
        discoveryId,
        challengeCode,
        position
      })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_SUCCESS,
          parameters: { discoveryId, challengeCode, position },
          payload: { discovery }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_FAILURE,
          error,
          parameters: { discoveryId, challengeCode, position }
        });
      });
  };

export const removeDiscoveryChallengeFromDiscovery =
  ({ discoveryId, challengeCode, isCBI }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST,
      parameters: { discoveryId, challengeCode, isCBI }
    });

    return discoveriesService
      .removeChallengeFromDiscovery({ discoveryId, challengeCode })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, challengeCode, isCBI }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_FAILURE,
          error,
          parameters: { discoveryId, challengeCode }
        });
      });
  };

export const saveDiscoveryKPI =
  ({ discoveryId, kpiCode, changes }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_KPI_REQUEST
    });

    return discoveriesService
      .saveDiscoveryKPI({ discoveryId, kpiCode, changes })
      .then(({ kpis, solutions, metrics }) => {
        dispatch({
          type: types.DISCOVERY_SAVE_KPI_SUCCESS,
          payload: { kpis, solutions, metrics }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_KPI_FAILURE,
          error
        });
      });
  };

export const previewDiscoveryKPI =
  ({ discoveryId, kpiCode, kpiChanges, metricChanges }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_PREVIEW_KPI_REQUEST,
      parameters: { kpiCode }
    });

    return discoveriesService
      .previewDiscoveryKPI({ discoveryId, kpiCode, kpiChanges, metricChanges })
      .then(({ discovery, formulaDescription }) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_KPI_SUCCESS,
          payload: { discovery, formulaDescription },
          parameters: { kpiCode }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_KPI_FAILURE,
          error
        });
      });
  };

export const commitPreviewDiscoveryKPI =
  ({ discoveryId, kpiCode, kpiChanges, metricChanges }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_COMMIT_PREVIEW_KPI_REQUEST
    });

    return discoveriesService
      .commitPreviewDiscoveryKPI({
        discoveryId,
        kpiCode,
        kpiChanges,
        metricChanges
      })
      .then(({ discovery, formulaDescription }) => {
        dispatch({
          type: types.DISCOVERY_COMMIT_PREVIEW_KPI_SUCCESS,
          payload: { discovery, formulaDescription }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_COMMIT_PREVIEW_KPI_FAILURE,
          error
        });
      });
  };

export const addDiscoveryKPIFromLibrary =
  ({ discoveryId, kpiCode, order }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST,
      parameters: { discoveryId, kpiCode, order }
    });

    return discoveriesService
      .addDiscoveryKPIFromLibrary({ discoveryId, kpiCode, order })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_ADD_KPI_FROM_LIBRARY_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, kpiCode, order }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_ADD_KPI_FROM_LIBRARY_FAILURE,
          error,
          parameters: { discoveryId, kpiCode, order }
        });
      });
  };

export const removeDiscoveryKPIFromLibrary =
  ({ discoveryId, kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST,
      parameters: { discoveryId, kpiCode }
    });

    return discoveriesService
      .removeDiscoveryKPIFromLibrary({ discoveryId, kpiCode })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, kpiCode }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_FAILURE,
          error,
          parameters: { discoveryId, kpiCode }
        });
      });
  };

export const freezeDiscoveryKPIs = (discoveryId) => (dispatch) => {
  dispatch({
    type: types.FREEZE_DISCOVERY_KPIS_REQUEST
  });

  return discoveriesService
    .freezeDiscoveryKPIs({ discoveryId })
    .then((payload) => {
      dispatch({
        type: types.FREEZE_DISCOVERY_KPIS_SUCCESS,
        payload
      });

      return true;
    })
    .catch((error) => {
      dispatch({
        type: types.FREEZE_DISCOVERY_KPIS_FAILURE,
        error
      });
    });
};

export const deleteDiscoveryKPI =
  ({ discoveryId, kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_DELETE_KPI_REQUEST,
      parameters: { discoveryId, kpiCode }
    });

    return discoveriesService
      .deleteDiscoveryKPI({ discoveryId, kpiCode })
      .then(() => {
        dispatch({
          type: types.DISCOVERY_DELETE_KPI_SUCCESS,
          payload: kpiCode,
          parameters: { discoveryId, kpiCode }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_DELETE_KPI_FAILURE,
          error,
          parameters: { discoveryId, kpiCode }
        });
      });
  };

export const restoreDiscoveryKPI =
  ({ discoveryId, kpiCode }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_RESTORE_KPI_REQUEST,
      parameters: { discoveryId, kpiCode }
    });

    return discoveriesService
      .restoreDiscoveryKPI({ discoveryId, kpiCode })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_RESTORE_KPI_SUCCESS,
          payload: { discovery },
          parameters: { discoveryId, kpiCode }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_RESTORE_KPI_FAILURE,
          error,
          parameters: { discoveryId, kpiCode }
        });
      });
  };

export const saveDiscoveryMetric =
  ({ discoveryId, metricCode, changes }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_METRIC_REQUEST
    });

    return discoveriesService
      .saveDiscoveryMetric({ discoveryId, metricCode, changes })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SAVE_METRIC_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_METRIC_FAILURE,
          payload: { error }
        });
      });
  };

export const resetDiscoveryMetric =
  ({ discoveryId, metricCode }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_RESET_METRIC_REQUEST
    });

    return discoveriesService
      .resetDiscoveryMetric({ discoveryId, metricCode })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_RESET_METRIC_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_RESET_METRIC_FAILURE,
          payload: { error }
        });
      });
  };

export const saveDiscoveryROI =
  ({ discoveryId, changes }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_ROI_REQUEST
    });

    return discoveriesService
      .saveDiscoveryROI({ discoveryId, changes })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SAVE_ROI_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_ROI_FAILURE,
          payload: { error }
        });
      });
  };

export const fetchMyDiscoveries = (filters) => (dispatch) => {
  dispatch({
    type: types.DISCOVERIES_FETCH_REQUEST
  });

  return discoveriesService
    .getMyDiscoveries(filters)
    .then(({ pagination, items, onlySamples }) => {
      dispatch({
        type: types.DISCOVERIES_FETCH_SUCCESS,
        payload: { discoveries: items, pagination, filters, onlySamples }
      });
    })
    .catch((error) => {
      dispatch({
        type: types.DISCOVERIES_FETCH_FAILURE,
        payload: { error }
      });
    });
};

export const addDiscovery = (discoveryData) => (dispatch) => {
  dispatch({
    type: types.ADD_DISCOVERY_REQUEST
  });

  return discoveriesService
    .addDiscovery(discoveryData)
    .then((discovery) => {
      dispatch({
        type: types.ADD_DISCOVERY_SUCCESS,
        payload: { discovery }
      });
    })
    .catch((error) => {
      dispatch({
        type: types.ADD_DISCOVERY_FAILURE,
        payload: { error }
      });
    });
};

export const createSampleDiscoveries = () => (dispatch) => {
  dispatch({
    type: types.CREATE_SAMPLE_DISCOVERIES_REQUEST
  });

  return discoveriesService
    .createSampleDiscoveries()
    .then(() => {
      dispatch({
        type: types.CREATE_SAMPLE_DISCOVERIES_SUCCESS
      });
    })
    .catch((error) => {
      dispatch({
        type: types.CREATE_SAMPLE_DISCOVERIES_FAILURE,
        payload: { error }
      });
    });
};

export const cloneDiscovery =
  ({ discoveryId, discoveryData }) =>
  (dispatch) => {
    dispatch({
      type: types.ADD_DISCOVERY_REQUEST
    });

    return discoveriesService
      .cloneDiscovery({ discoveryId, discoveryData })
      .then((discovery) => {
        dispatch({
          type: types.ADD_DISCOVERY_SUCCESS,
          payload: { discovery }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ADD_DISCOVERY_FAILURE,
          payload: { error }
        });
      });
  };

export const updateDiscovery =
  ({ discoveryId, data }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_DISCOVERY_REQUEST
    });

    delete data.discoveryTypeCode;

    return discoveriesService
      .updateDiscovery({ discoveryId, data })
      .then((discovery) => {
        dispatch({
          type: types.UPDATE_DISCOVERY_SUCCESS,
          payload: { discovery }
        });

        return discovery;
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_DISCOVERY_FAILURE,
          error
        });
      });
  };

export const updateDiscoveryCanvas =
  ({ discoveryId, canvasLogoFileId, canvasTitle }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_DISCOVERY_CANVAS_REQUEST,
      parameters: { canvasLogoFileId, canvasTitle }
    });

    return discoveriesService
      .updateDiscoveryCanvas({
        discoveryId,
        canvasLogoFileId,
        canvasTitle
      })
      .then((discovery) => {
        dispatch({
          type: types.UPDATE_DISCOVERY_CANVAS_SUCCESS,
          payload: { discovery },
          parameters: { canvasLogoFileId, canvasTitle }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.UPDATE_DISCOVERY_CANVAS_FAILURE,
          error,
          parameters: { canvasLogoFileId }
        });
      });
  };

export const assignDiscovery =
  ({ discoveryId, userId, canSeeAllDiscoveries }) =>
  (dispatch) => {
    dispatch({
      type: types.ASSIGN_DISCOVERY_REQUEST
    });

    return discoveriesService
      .updateDiscovery({
        discoveryId,
        data: {
          cognitoUserGuid: userId
        }
      })
      .then((discovery) => {
        dispatch({
          type: types.ASSIGN_DISCOVERY_SUCCESS,
          payload: { discovery, canSeeAllDiscoveries }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.ASSIGN_DISCOVERY_FAILURE,
          payload: { error }
        });
      });
  };

export const deleteDiscovery =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_DISCOVERY_REQUEST
    });

    return discoveriesService
      .deleteDiscovery({ discoveryId })
      .then(() => {
        dispatch({
          type: types.DELETE_DISCOVERY_SUCCESS,
          payload: { discoveryId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_DISCOVERY_FAILURE,
          error
        });
      });
  };

export const getDiscoveryExportTemplates =
  ({ discoveryId, format }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST
    });

    return discoveriesService
      .getExportTemplates({ discoveryId, format })
      .then((payload) => {
        dispatch({
          type: types.GET_DISCOVERY_EXPORT_TEMPLATES_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DISCOVERY_EXPORT_TEMPLATES_FAILURE,
          error
        });
      });
  };

export const exportDiscovery =
  ({ reportId, template, format }) =>
  (dispatch) => {
    const key = uuidv4();

    dispatch({
      type: types.DISCOVERY_EXPORT_REQUEST,
      payload: { key }
    });

    return discoveriesService
      .exportDiscovery({ reportId, template, format })
      .then((data) => {
        dispatch({
          type: types.DISCOVERY_EXPORT_SUCCESS,
          payload: { ...data, key }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_EXPORT_FAILURE,
          error
        });
      });
  };

export const dismissDiscoveryKPIInfoBox = () => (dispatch) => {
  dispatch({
    type: types.DISMISS_DISCOVERY_KPI_INFOBOX
  });
};
export const resetDiscoveryKPIInfoBox =
  ({ showKPIInfoBox }) =>
  (dispatch) => {
    dispatch({
      type: types.RESET_DISCOVERY_KPI_INFOBOX,
      payload: showKPIInfoBox
    });
  };

export const saveDiscoverySolutions =
  ({ discoveryId, solutionsMap }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SAVE_SOLUTIONS_REQUEST
    });

    return discoveriesService
      .saveDiscoverySolutions({ discoveryId, solutionsMap })
      .then(({ discovery, difference }) => {
        dispatch({
          type: types.DISCOVERY_SAVE_SOLUTIONS_SUCCESS,
          payload: { discovery, difference }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SAVE_SOLUTIONS_FAILURE,
          payload: { error }
        });
      });
  };

export const previewDiscoverySolutions =
  ({ discoveryId, solutionsMap }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_PREVIEW_SOLUTIONS_REQUEST
    });

    return discoveriesService
      .previewDiscoverySolutions({ discoveryId, solutionsMap })
      .then((solutionsMap) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_SOLUTIONS_SUCCESS,
          payload: { solutionsMap }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_PREVIEW_SOLUTIONS_FAILURE,
          payload: { error }
        });
      });
  };

export const requestDiscoveryROI =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_REQUEST_ROI_REQUEST
    });

    return discoveriesService
      .requestROI({ discoveryId })
      .then(() => {
        dispatch({
          type: types.DISCOVERY_REQUEST_ROI_SUCCESS
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_REQUEST_ROI_FAILURE,
          payload: { error }
        });
      });
  };

export const registerDiscoveryInterest =
  ({ discoveryId, ...data }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_REGISTER_INTEREST_REQUEST
    });

    return discoveriesService
      .registerInterest({
        discoveryId,
        ...data
      })
      .then((discovery) => {
        dispatch({
          type: types.DISCOVERY_REGISTER_INTEREST_SUCCESS,
          payload: { discovery }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_REGISTER_INTEREST_FAILURE,
          error
        });
      });
  };

export const getDiscoveryEmailContent =
  ({ discoveryId, subjectTemplate, bodyTemplate, data }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_GENERATE_EMAIL_REQUEST
    });

    return discoveriesService
      .getEmailContent({
        discoveryId,
        subjectTemplate,
        bodyTemplate,
        data
      })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_GENERATE_EMAIL_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_GENERATE_EMAIL_FAILURE,
          error
        });
      });
  };

export const calculateDiscoveryKPIFormula =
  ({ discoveryId, kpiCode, estimationScenario }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST
    });

    return discoveriesService
      .calculateKPIFormula({
        discoveryId,
        kpiCode,
        estimationScenario
      })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_CALCULATE_KPI_FORMULA_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_CALCULATE_KPI_FORMULA_FAILURE,
          error
        });
      });
  };

export const calculateDiscoveryKPIFormulaByReportId =
  ({ reportId, kpiCode, estimationScenario }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST
    });

    return reportsService
      .calculateKPIFormula({
        reportId,
        kpiCode,
        estimationScenario
      })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_CALCULATE_KPI_FORMULA_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_CALCULATE_KPI_FORMULA_FAILURE,
          error
        });
      });
  };

export const getDiscoverySummary =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_SUMMARY_REQUEST
    });

    return discoveriesService
      .getDiscoverySummary({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_SUMMARY_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_SUMMARY_FAILURE,
          error
        });
      });
  };

export const updateDiscoverySettings =
  ({ discoveryId, changes }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_UPDATE_SETTINGS_REQUEST
    });

    return discoveriesService
      .updateDiscoverySettings({ discoveryId, changes })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_UPDATE_SETTINGS_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_UPDATE_SETTINGS_FAILURE,
          error
        });
      });
  };

export const updateDiscoverySettingsGroup =
  ({ discoveryId, groupId, status }) =>
  (dispatch) => {
    dispatch({
      type: types.DISCOVERY_UPDATE_SETTINGS_GROUP_REQUEST,
      parameters: { groupId, status }
    });

    return discoveriesService
      .updateDiscoverySettingsGroup({ discoveryId, groupId, status })
      .then((payload) => {
        dispatch({
          type: types.DISCOVERY_UPDATE_SETTINGS_GROUP_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.DISCOVERY_UPDATE_SETTINGS_GROUP_FAILURE,
          error
        });
      });
  };

export const exportDiscoveries =
  ({ format }) =>
  (dispatch) => {
    const key = uuidv4();

    dispatch({
      type: types.EXPORT_DISCOVERIES_REQUEST,
      payload: { key }
    });

    return discoveriesService
      .exportDiscoveries({ format })
      .then((payload) => {
        dispatch({
          type: types.EXPORT_DISCOVERIES_SUCCESS,
          payload: { ...payload, key }
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.EXPORT_DISCOVERIES_FAILURE,
          error
        });
      });
  };

export const getDiscoveryChallengeLibrary =
  ({ discoveryId, start, count, search, sort, order }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_CHALLENGE_LIBRARY_REQUEST
    });

    return discoveriesService
      .getDiscoveryChallengeLibrary({
        discoveryId,
        start,
        count,
        search,
        sort,
        order
      })
      .then((payload) => {
        dispatch({
          type: types.GET_CHALLENGE_LIBRARY_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_CHALLENGE_LIBRARY_FAILURE,
          error
        });
      });
  };

export const getDiscoveryKPILibrary =
  ({ discoveryId, start, count, search, sort, order, hasFormula }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_KPI_LIBRARY_REQUEST
    });

    return discoveriesService
      .getDiscoveryKPILibrary({
        discoveryId,
        start,
        count,
        search,
        sort,
        order,
        hasFormula
      })
      .then((payload) => {
        dispatch({
          type: types.GET_KPI_LIBRARY_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_KPI_LIBRARY_FAILURE,
          error
        });
      });
  };

export const getAutocompleteDiscoveryChallengeLibrary =
  ({ discoveryId, start, count, search, sort, order, searchCategories }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_REQUEST
    });

    return discoveriesService
      .getDiscoveryChallengeLibrary({
        discoveryId,
        start,
        count,
        search,
        sort,
        order,
        searchCategories
      })
      .then((payload) => {
        dispatch({
          type: types.GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_FAILURE,
          error
        });
      });
  };

export const getAutocompleteDiscoveryKPILibrary =
  ({
    discoveryId,
    start,
    count,
    search,
    sort,
    order,
    hasFormula,
    searchCategories
  }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_AUTOCOMPLETE_KPI_LIBRARY_REQUEST
    });

    return discoveriesService
      .getDiscoveryKPILibrary({
        discoveryId,
        start,
        count,
        search,
        sort,
        order,
        hasFormula,
        searchCategories
      })
      .then((payload) => {
        dispatch({
          type: types.GET_AUTOCOMPLETE_KPI_LIBRARY_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_AUTOCOMPLETE_KPI_LIBRARY_FAILURE,
          error
        });
      });
  };

export const reorderDiscoveryKPIs =
  ({ discoveryId, codes }) =>
  (dispatch) => {
    dispatch({
      type: types.REORDER_DISCOVERY_KPIS_REQUEST
    });

    return discoveriesService
      .reorderDiscoveryKPIs({ discoveryId, codes })
      .then((payload) => {
        dispatch({
          type: types.REORDER_DISCOVERY_KPIS_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.REORDER_DISCOVERY_KPIS_FAILURE,
          error
        });
      });
  };

export const reorderDiscoveryChallenges =
  ({ discoveryId, keyObjectiveCode, codes }) =>
  (dispatch) => {
    dispatch({
      type: types.REORDER_DISCOVERY_CHALLENGES_REQUEST
    });

    return discoveriesService
      .reorderDiscoveryChallenges({ discoveryId, keyObjectiveCode, codes })
      .then((payload) => {
        dispatch({
          type: types.REORDER_DISCOVERY_CHALLENGES_SUCCESS,
          payload
        });

        return true;
      })
      .catch((error) => {
        dispatch({
          type: types.REORDER_DISCOVERY_CHALLENGES_FAILURE,
          error
        });
      });
  };

export const shareDiscoveryAccess =
  ({ discoveryId, emails, accessType, sendEmail, message, discoveryStage }) =>
  (dispatch) => {
    dispatch({
      type: types.SHARE_DISCOVERY_ACCESS_REQUEST
    });

    return discoveriesService
      .shareDiscoveryAccess({
        discoveryId,
        emails,
        accessType,
        sendEmail,
        message,
        discoveryStage
      })
      .then(({ discovery, linkUrl }) => {
        dispatch({
          type: types.SHARE_DISCOVERY_ACCESS_SUCCESS,
          payload: { discovery, linkUrl }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.SHARE_DISCOVERY_ACCESS_FAILURE,
          payload: { error }
        });
      });
  };

export const updateDiscoveryEngagementCounts =
  ({ discoveryId, externalEngagementCount, externalReportViewCount }) =>
  (dispatch) => {
    dispatch({
      type: types.UPDATE_DISCOVERY_ENGAGEMENT_COUNTS,
      payload: {
        discoveryId,
        externalEngagementCount,
        externalReportViewCount
      }
    });
  };

export const getDiscoveryNotifications =
  ({ discoveryId, external, start = 0, count = 50, sort, order }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_DISCOVERY_NOTIFICATIONS_REQUEST
    });

    return discoveriesService
      .getDiscoveryNotifications({
        discoveryId,
        external,
        start,
        count,
        sort,
        order
      })
      .then(({ items, pagination }) => {
        dispatch({
          type: types.GET_DISCOVERY_NOTIFICATIONS_SUCCESS,
          payload: { notifications: items, pagination }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DISCOVERY_NOTIFICATIONS_FAILURE,
          payload: { error }
        });
      });
  };

export const getDiscoveryInvitations =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.GET_DISCOVERY_INVITATIONS_REQUEST
    });

    return discoveriesService
      .getDiscoveryInvitations({
        discoveryId
      })
      .then((items) => {
        dispatch({
          type: types.GET_DISCOVERY_INVITATIONS_SUCCESS,
          payload: items,
          parameters: { discoveryId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.GET_DISCOVERY_INVITATIONS_FAILURE,
          error
        });
      });
  };

export const deleteDiscoveryInvitation =
  ({ discoveryId, invitationId }) =>
  (dispatch) => {
    dispatch({
      type: types.DELETE_DISCOVERY_INVITATION_REQUEST
    });

    return discoveriesService
      .deleteDiscoveryInvitation({ discoveryId, invitationId })
      .then(() => {
        dispatch({
          type: types.DELETE_DISCOVERY_INVITATION_SUCCESS,
          parameters: { discoveryId, invitationId }
        });
      })
      .catch((error) => {
        dispatch({
          type: types.DELETE_DISCOVERY_INVITATION_FAILURE,
          error
        });
      });
  };

export const resetDiscoveryAiConversation =
  ({ discoveryId }) =>
  (dispatch) => {
    dispatch({
      type: types.RESET_DISCOVERY_AI_CONVERSATION_REQUEST
    });

    return discoveriesService
      .resetDiscoveryAiConversation({ discoveryId })
      .then((payload) => {
        dispatch({
          type: types.RESET_DISCOVERY_AI_CONVERSATION_SUCCESS,
          payload
        });
      })
      .catch((error) => {
        dispatch({
          type: types.RESET_DISCOVERY_AI_CONVERSATION_FAILURE,
          error
        });
      });
  };

const discoveries = {
  fetchMyDiscoveries,
  addDiscovery,
  fetchDiscovery,
  saveDiscoveryQuestion,
  saveDiscoveryChallenge,
  saveDiscoveryKPI,
  saveDiscoveryMetric,
  cloneDiscovery,
  updateDiscovery,
  deleteDiscovery,
  getDiscoverySummary,
  getDiscoveryKPILibrary,
  getDiscoveryNotifications,
  getDiscoveryInvitations,
  updateDiscoveryEngagementCounts,
  resetDiscoveryAiConversation
};

export default discoveries;
