import _ from "lodash";
import types from "../actionTypes";
import LoadingState from "../../utils/loading-state";
import SolutionStatus from "../../utils/solution-status";
import ViewKPIsBy from "../../utils/view-kpis-by";
import Scenarios from "../../Pages/ValueHypothesis/components/Scenarios";
import DelayTimelineOptions from "../../Pages/ValueHypothesis/components/DelayTimelineOptions";
import { sortDiscoveryKPIsByOrder } from "../../utils/sorting";

const INITIAL_STATE = {
  discovery: false,
  error: null,
  loadingState: LoadingState.UNINITIALIZED,
  refreshLoadingState: LoadingState.UNINITIALIZED,
  resetMetricError: false,
  showKPIInfoBox: true,
  saveSolutionsLoadingState: LoadingState.UNINITIALIZED,
  requestROILoadingState: LoadingState.UNINITIALIZED,
  difference: null
};

const updateDiscovery = ({ oldDiscovery, newDiscovery }) => {
  return {
    ...oldDiscovery,
    ...newDiscovery
  };
};

const addKPIsToDiscovery = ({ discovery, kpis }) => {
  return {
    ...discovery,
    kpis: [...discovery.kpis, ...kpis]
  };
};

const deleteKPIFromDiscovery = ({ discovery, kpiCode }) => {
  return {
    ...discovery,
    kpis: [...discovery.kpis.filter((kpi) => kpi.kpiCode !== kpiCode)]
  };
};

const replaceKPIsAndMetricsInDiscovery = ({ discovery, kpis, metrics }) => {
  const kpisMap = kpis.reduce((map, kpi) => {
    map[kpi.kpiCode] = kpi;
    return map;
  }, {});

  return {
    ...discovery,
    kpis: discovery.kpis.map((k) =>
      kpisMap[k.kpiCode] ? kpisMap[k.kpiCode] : k
    ),
    metrics
  };
};

const replaceROIAndCashflowInDiscovery = ({ discovery, roi, cashflow }) => {
  return {
    ...discovery,
    roi,
    cashflow
  };
};

const replaceScenarioInDiscovery = ({ discovery, scenario }) => {
  return {
    ...discovery,
    scenario
  };
};

const replaceDelayTimelineInDiscovery = ({ discovery, delayTimeline }) => {
  return {
    ...discovery,
    delayTimeline
  };
};

export const replaceCheckpointInDiscovery = ({
  discovery,
  checkpoint,
  phase,
  lastViewedPage
}) => {
  return {
    ...discovery,
    checkpoint,
    phase,
    lastViewedPage
  };
};

const replaceGroupInDiscoverySettings = ({ settings, groupId, status }) => {
  const groups = settings ? { ...settings.groups } : {};

  groups[groupId] = status;

  return {
    ...settings,
    groups
  };
};

const discovery = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case types.DISCOVERY_FETCH_REQUEST:
      return {
        ...state,
        error: false,
        loadingState: LoadingState.IN_PROGRESS
      };
    case types.DISCOVERY_COMMIT_PREVIEW_KPI_SUCCESS:
    case types.DISCOVERY_FETCH_SUCCESS:
      return {
        ...state,
        discovery: action.payload.discovery,
        loadingState: LoadingState.COMPLETED
      };
    case types.DISCOVERY_FETCH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        loadingState: LoadingState.FAILED
      };

    case types.DISCOVERY_SAVE_QUESTION_SUCCESS:
      return {
        ...state,
        discovery: action.payload.discovery
      };

    case types.RESET_DISCOVERY_AI_CONVERSATION_SUCCESS:
      return {
        ...state,
        discovery: action.payload
      };

    case types.DISCOVERY_REFRESH_REQUEST:
      return {
        ...state,
        error: null,
        refreshLoadingState: LoadingState.IN_PROGRESS
      };
    case types.DISCOVERY_REFRESH_SUCCESS:
      return {
        ...state,
        discovery: action.payload.discovery,
        refreshLoadingState: LoadingState.COMPLETED
      };
    case types.DISCOVERY_REFRESH_FAILURE:
      return {
        ...state,
        error: action.payload.error,
        refreshLoadingState: LoadingState.FAILED
      };

    case types.DISCOVERY_REGISTER_INTEREST_SUCCESS:
    case types.DISCOVERY_RESET_SUCCESS:
    case types.DISCOVERY_SAVE_CHALLENGE_SUCCESS:
    case types.DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_SUCCESS:
    case types.DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_SUCCESS:
    case types.DISCOVERY_DELETE_CHALLENGE_SUCCESS:
    case types.DISCOVERY_RESTORE_CHALLENGE_SUCCESS:
    case types.DISCOVERY_RESTORE_KPI_SUCCESS:
    case types.UPDATE_DISCOVERY_CANVAS_SUCCESS:
    case types.DISCOVERY_ADD_KPI_FROM_LIBRARY_SUCCESS:
    case types.DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_SUCCESS:
    case types.SHARE_DISCOVERY_ACCESS_SUCCESS:
    case types.DISCOVERY_CREATE_CHALLENGE_SUCCESS:
      return {
        ...state,
        discovery: action.payload.discovery
      };

    case types.DISCOVERY_SAVE_KPI_SUCCESS:
      const kpiDiscovery = replaceKPIsAndMetricsInDiscovery({
        discovery: state.discovery,
        kpis: action.payload.kpis,
        metrics: action.payload.metrics
      });
      kpiDiscovery.solutions = action.payload.solutions;

      return {
        ...state,
        discovery: kpiDiscovery
      };
    case types.DISCOVERY_CREATE_KPI_SUCCESS:
      return {
        ...state,
        discovery: addKPIsToDiscovery({
          discovery: state.discovery,
          kpis: [action.payload]
        })
      };
    case types.DISCOVERY_DELETE_KPI_SUCCESS:
      return {
        ...state,
        discovery: deleteKPIFromDiscovery({
          discovery: state.discovery,
          kpiCode: action.payload
        })
      };

    case types.DISCOVERY_RESET_METRIC_REQUEST:
      return {
        ...state,
        resetMetricError: false
      };
    case types.DISCOVERY_SAVE_METRIC_SUCCESS:
    case types.DISCOVERY_RESET_METRIC_SUCCESS:
      return {
        ...state,
        discovery: action.payload
      };
    case types.DISCOVERY_RESET_METRIC_FAILURE:
      return {
        ...state,
        resetMetricError: action.payload.error
      };
    case types.DISCOVERY_SAVE_ROI_SUCCESS:
      return {
        ...state,
        discovery: replaceROIAndCashflowInDiscovery({
          discovery: state.discovery,
          roi: action.payload.roi,
          cashflow: action.payload.cashflow
        })
      };
    case types.DISCOVERY_SAVE_SCENARIO_SUCCESS:
      return {
        ...state,
        discovery: replaceScenarioInDiscovery({
          discovery: state.discovery,
          scenario: action.payload.scenario
        })
      };
    case types.DISCOVERY_SAVE_DELAY_TIMELINE_SUCCESS:
      return {
        ...state,
        discovery: replaceDelayTimelineInDiscovery({
          discovery: state.discovery,
          delayTimeline: action.payload.delayTimeline
        })
      };
    case types.DISCOVERY_SET_SCENARIO_SUCCESS:
      return {
        ...state,
        discovery: replaceScenarioInDiscovery({
          discovery: state.discovery,
          scenario: action.payload
        })
      };
    case types.DISCOVERY_SAVE_ROI_BENEFITS_SUCCESS:
      return {
        ...state,
        discovery: replaceROIAndCashflowInDiscovery({
          discovery: state.discovery,
          roi: action.payload.roi,
          cashflow: action.payload.cashflow
        })
      };
    case types.DISCOVERY_PREVIEW_CASHFLOW_SUCCESS:
      return {
        ...state,
        discovery: replaceROIAndCashflowInDiscovery({
          discovery: state.discovery,
          roi: action.payload.roi,
          cashflow: action.payload.cashflow
        })
      };

    case types.DISCOVERY_SAVE_CHECKPOINT_REQUEST:
      return state;
    case types.DISCOVERY_SAVE_CHECKPOINT_SUCCESS:
      return {
        ...state,
        discovery: replaceCheckpointInDiscovery({
          discovery: state.discovery,
          checkpoint: action.payload.checkpoint,
          phase: action.payload.phase,
          lastViewedPage: action.payload.lastViewedPage
        })
      };
    case types.DISCOVERY_SAVE_CHECKPOINT_FAILURE:
      return state;

    case types.DISMISS_DISCOVERY_KPI_INFOBOX:
      return {
        ...state,
        showKPIInfoBox: false
      };
    case types.RESET_DISCOVERY_KPI_INFOBOX:
      return {
        ...state,
        showKPIInfoBox: action.payload
      };
    case types.UPDATE_DISCOVERY_SUCCESS:
      if (
        state.discovery &&
        state.discovery._id === action.payload.discovery._id
      ) {
        return {
          ...state,
          discovery: updateDiscovery({
            oldDiscovery: state.discovery,
            newDiscovery: action.payload.discovery
          })
        };
      }
      return state;

    case types.DISCOVERY_SAVE_SOLUTIONS_REQUEST:
      return {
        ...state,
        saveSolutionsLoadingState: LoadingState.IN_PROGRESS
      };
    case types.DISCOVERY_SAVE_SOLUTIONS_FAILURE:
      return {
        ...state,
        saveSolutionsLoadingState: LoadingState.FAILED
      };
    case types.DISCOVERY_SAVE_SOLUTIONS_SUCCESS:
      return {
        ...state,
        saveSolutionsLoadingState: LoadingState.COMPLETED,
        discovery: action.payload.discovery,
        difference: action.payload.difference
      };

    case types.DISCOVERY_REQUEST_ROI_REQUEST:
      return {
        ...state,
        requestROILoadingState: LoadingState.IN_PROGRESS
      };
    case types.DISCOVERY_REQUEST_ROI_SUCCESS:
      return {
        ...state,
        requestROILoadingState: LoadingState.COMPLETED
      };
    case types.DISCOVERY_REQUEST_ROI_FAILURE:
      return {
        ...state,
        requestROILoadingState: LoadingState.FAILED
      };

    case types.DISCOVERY_UPDATE_SETTINGS_GROUP_REQUEST:
      return {
        ...state,
        discovery: {
          ...state.discovery,
          settings: replaceGroupInDiscoverySettings({
            settings: state.discovery.settings,
            groupId: action.parameters.groupId,
            status: action.parameters.status
          })
        }
      };

    case types.DISCOVERY_UPDATE_SETTINGS_SUCCESS:
    case types.DISCOVERY_UPDATE_SETTINGS_GROUP_SUCCESS:
      return {
        ...state,
        discovery: {
          ...state.discovery,
          settings: action.payload
        }
      };

    case types.REORDER_DISCOVERY_CHALLENGES_SUCCESS:
    case types.REORDER_DISCOVERY_KPIS_SUCCESS:
      return {
        ...state,
        discovery: action.payload
      };
    case types.START_CONVERSATION_SUCCESS:
      return {
        ...state,
        discovery: {
          ...state.discovery,
          conversationId: action.payload._id
        }
      };

    default:
      return state;
  }
};

export default discovery;

export const selectDiscovery = (discoveryData) => discoveryData.discovery;
export const selectDiscoveryCanvasLogoPath = (discoveryData) =>
  discoveryData.discovery.canvasLogoPath;
export const selectDiscoveryCanvasTitle = (discoveryData) =>
  discoveryData.discovery.canvasTitle;
export const selectDiscoveryId = (discoveryData) => discoveryData.discovery._id;
export const selectDiscoveryConversationId = (discoveryData) =>
  discoveryData.discovery.conversationId;
export const selectDiscoveryUsername = (discoveryData) =>
  discoveryData.discovery.username;
export const selectDiscoveryEditors = (discoveryData) =>
  discoveryData.discovery.editors;
export const selectDiscoveryName = (discoveryData) =>
  discoveryData.discovery.name;
export const selectDiscoveryPhase = (discoveryData) =>
  discoveryData.discovery.phase;
export const selectDiscoveryMaximumPhase = (discoveryData) =>
  discoveryData.discovery.maximumPhase;
export const selectDiscoveryCurrency = (discoveryData) =>
  discoveryData.discovery.currency;
export const selectDiscoveryMasterDataVersion = (discoveryData) =>
  discoveryData.discovery.masterDataVersion;
export const selectDiscoveryTypeCode = (discoveryData) =>
  discoveryData.discovery ? discoveryData.discovery.discoveryTypeCode : null;
export const selectDiscoveryQuestion = (discoveryData, questionNumber) =>
  discoveryData.discovery
    ? discoveryData.discovery.questions[questionNumber]
    : null;
export const selectDiscoveryQuestionAnswer = (
  discoveryData,
  questionNumber
) => {
  return discoveryData.discovery &&
    discoveryData.discovery.questions[questionNumber]
    ? discoveryData.discovery.questions[questionNumber].answer
    : undefined;
};
export const selectDiscoveryQuestions = (discoveryData) =>
  discoveryData.discovery.questions;
export const selectDiscoveryChallenges = (discoveryData) =>
  discoveryData.discovery.challenges;

export const selectDiscoveryBusinessCriticalChallengeCode = (discoveryData) =>
  discoveryData?.discovery?.businessCriticalChallengeCode;

export const selectDiscoveryChallenge = (discoveryData, challengeCode) =>
  discoveryData.discovery.challenges.find(
    (challenge) => challenge.challengeCode === challengeCode
  );
export const selectDiscoverySelectedChallenges = (discoveryData) => {
  const selectedChallenges = discoveryData.discovery
    ? discoveryData.discovery.challenges.filter(
        (challenge) => challenge.selected && challenge.selectedByDefault
      )
    : [];

  selectedChallenges.sort((a, b) => b.importance - a.importance);
  return selectedChallenges;
};
export const selectDiscoveryMetricsCount = (discoveryData, canSeeAllMetrics) =>
  discoveryData.discovery.metrics.filter((m) => m.visible || canSeeAllMetrics)
    .length;

export const selectDiscoveryUngroupedMetricCodeString = (
  discoveryData,
  showInvisible
) => {
  const list = _.get(discoveryData, "discovery.metrics", []).filter(
    (metric) => (metric.visible || showInvisible) && !metric.groupCode
  );

  list.sort((a, b) => a.order - b.order);

  return list.map((metric) => metric.metricCode).join(",");
};

export const selectDiscoveryMetricCodeStringByGroup = (
  discoveryData,
  groupCode,
  showInvisible
) => {
  const list = discoveryData.discovery.metrics.filter(
    (metric) =>
      (metric.visible || showInvisible) && metric.groupCode === groupCode
  );

  list.sort((a, b) => a.order - b.order);

  return list.map((metric) => metric.metricCode).join(",");
};

export const selectDiscoveryMetric = (discoveryData, metricCode) =>
  discoveryData.discovery.metrics.find(
    (metric) => metric.metricCode === metricCode
  );

export const selectDiscoveryMetricGroupCodeString = (
  discoveryData,
  showInvisible
) => {
  const map = {};
  const list = [];
  const metrics = _.get(discoveryData, "discovery.metrics", []);

  for (let i = 0; i < metrics.length; i++) {
    if (metrics[i].groupCode && (metrics[i].visible || showInvisible)) {
      map[metrics[i].groupCode] = 1;
    }
  }

  for (let key in map) {
    list.push(key);
  }

  return list.join(",");
};

export const selectDiscoverySelectedKPIs = (discoveryData) => {
  return discoveryData.discovery.kpis
    ? discoveryData.discovery.kpis.filter(
        (kpi) => kpi.selected && kpi.relevant && kpi.inScope
      )
    : [];
};

export const selectDiscoveryKPIs = (discoveryData) => {
  return discoveryData?.discovery?.kpis?.filter((k) => k.inScope);
};

export const selectDiscoveryAllKPIs = (discoveryData) => {
  return discoveryData?.discovery?.kpis;
};

export const selectDiscoveryUngroupedKPIs = (discoveryData, showKPIs) => {
  const kpis = discoveryData.discovery.kpis.filter(
    (kpi) =>
      !kpi.definition.groupCode &&
      kpi.relevant &&
      kpi.inScope &&
      (kpi.selected || showKPIs !== "selected")
  );

  kpis.sort(sortDiscoveryKPIsByOrder);

  return kpis;
};

export const selectDiscoveryKPIsByGroup = (
  discoveryData,
  groupCode,
  showKPIs
) => {
  const kpis = discoveryData.discovery.kpis.filter(
    (kpi) =>
      kpi.definition.groupCode === groupCode &&
      kpi.relevant &&
      kpi.inScope &&
      (kpi.selected || showKPIs !== "selected")
  );

  kpis.sort(sortDiscoveryKPIsByOrder);

  return kpis;
};

export const selectDiscoveryKPI = (discoveryData, kpiCode) =>
  discoveryData.discovery.kpis.find((kpi) => kpi.kpiCode === kpiCode);

export const selectDiscoveryKPIGroupCodeString = (discoveryData, showKPIs) => {
  const map = {};
  const list = [];

  for (const discoveryKPI of discoveryData.discovery.kpis) {
    if (
      discoveryKPI.definition.groupCode &&
      discoveryKPI.relevant &&
      discoveryKPI.inScope &&
      (discoveryKPI.selected || showKPIs !== "selected")
    ) {
      map[discoveryKPI.definition.groupCode] = 1;
    }
  }

  for (let key in map) {
    list.push(key);
  }

  return list.join(",");
};

export const selectDiscoveryResetMetricError = (discoveryData) =>
  discoveryData.resetMetricError;

export const selectDiscoveryROI = (discoveryData) =>
  discoveryData.discovery ? discoveryData.discovery.roi : null;

export const selectDiscoveryScenario = (discoveryData) =>
  Number.isInteger(discoveryData?.discovery?.scenario)
    ? discoveryData?.discovery?.scenario
    : Scenarios.EXPECTED;

export const selectDiscoveryDelayTimeline = (discoveryData) =>
  discoveryData?.discovery?.delayTimeline
    ? discoveryData?.discovery?.delayTimeline
    : DelayTimelineOptions.YEAR;

export const selectDiscoveryLoadingState = (discoveryData) =>
  discoveryData.loadingState;
export const selectDiscoveryLoadingError = (discoveryData) =>
  discoveryData.error;
export const selectDiscoveryRefreshLoadingState = (discoveryData) =>
  discoveryData.refreshLoadingState;

export const selectDiscoveryNewKPIOrder = (discoveryData, kpiCode, viewBy) => {
  if (viewBy === ViewKPIsBy.IMPORTANCE) {
    const maxOrder = discoveryData.discovery.kpis.reduce(
      (max, discoveryKPI) => {
        return !max || discoveryKPI.definition.order > max
          ? discoveryKPI.definition.order
          : max;
      },
      0
    );

    return maxOrder + 10;
  }

  const discoveryKPI = selectDiscoveryKPI(discoveryData, kpiCode);

  if (!discoveryKPI) {
    return 0;
  }

  const kpis = discoveryData.discovery.kpis.filter(
    (kpi) =>
      kpi.definition.groupCode === discoveryKPI.definition.groupCode ||
      (!kpi.definition.groupCode && !discoveryKPI.definition.groupCode)
  );
  kpis.sort(sortDiscoveryKPIsByOrder);

  const index = kpis.findIndex((kpi) => kpi === discoveryKPI);

  if (index < kpis.length - 1) {
    const beforeOrder = kpis[index].definition.order;
    const afterOrder = kpis[index + 1].definition.order;

    return (beforeOrder + afterOrder) / 2;
  } else {
    return discoveryKPI.definition.order + 1;
  }
};

export const selectDiscoveryActiveSolutionCodes = (
  discoveryData,
  solutionCodes
) => {
  return solutionCodes.filter(
    (code) =>
      !discoveryData.discovery.solutions ||
      !SolutionStatus.isEffectivelyOutOfScope(
        discoveryData.discovery.solutions[code]
      )
  );
};

export const selectDiscoverySolutions = (discoveryData) => {
  return discoveryData?.discovery?.solutions || {};
};

export const selectDiscoverySaveSolutionsLoadingState = (discoveryData) =>
  discoveryData.saveSolutionsLoadingState;

export const selectDiscoveryGroup = (discoveryData, groupCode) => {
  return discoveryData?.discovery?.groups[groupCode] || {};
};

export const selectDiscoveryGroups = (discoveryData) => {
  return discoveryData?.discovery?.groups || {};
};

export const isDiscoveryROIComplete = (discoveryData) => {
  return !!(
    discoveryData.discovery &&
    discoveryData.discovery.roi &&
    Object.keys(discoveryData.discovery.roi).length
  );
};

export const selectDiscoveryRequestROILoadingState = (discoveryData) =>
  discoveryData.requestROILoadingState;

export const isDiscoveryRegisterInterestComplete = (discoveryData) =>
  !!discoveryData?.discovery?.interest;

export const selectDiscoveryCheckpoint = (discoveryData) =>
  discoveryData?.discovery?.checkpoint;

export const selectDiscoveryLastViewedPage = (discoveryData) =>
  discoveryData?.discovery?.lastViewedPage;

export const selectDiscoveryDifference = (discoveryData) =>
  discoveryData.difference;

export const selectDiscoveryReportId = (discoveryData) =>
  discoveryData?.discovery?.reportId;

export const selectDiscoverySetting = (discoveryData, field, defaultValue) =>
  discoveryData?.discovery?.settings
    ? discoveryData?.discovery?.settings.hasOwnProperty(field)
      ? discoveryData?.discovery?.settings[field]
      : defaultValue
    : defaultValue;

export const selectDiscoveryCashflow = (discoveryData) =>
  discoveryData?.discovery?.cashflow;
