import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useStringTemplate } from "../../../components/StringProvider";
import { IntlMessageFormat } from "intl-messageformat";
import { InputGroup, NumberInput } from "../../../components/Input";
import { getMetricFormatter, getMetricParser } from "../../../utils/formatting";
import MetricTypes from "../../../utils/metric-types";
import { SettingsContext } from "../../../components/SettingsProvider";
import { useString as s } from "../../../components/StringProvider";
import Icon from "../../../components/Icon";
import NewButton from "../../../components/NewButton";
import { themeProp } from "../../../theme";

const ListOfPercentageValues = ({
  length,
  currency,
  onChange,
  value,
  disabled
}) => {
  const [values, setValues] = useState([]);
  const { settings } = useContext(SettingsContext);
  const { locale } = settings;
  const yearTemplate = useStringTemplate(
    "discovery.roi.form.yearTemplate",
    "Year {year}"
  );
  const resetTooltip = s(
    "discovery.roi.form.benefitPercentage.reset.tooltip",
    "Click to reset values to 100%"
  );

  useEffect(() => {
    if (Array.isArray(value) && !_.isEqual(value, values)) {
      setValues(value);
    }
  }, []);

  useEffect(() => {
    setValues((currentValues) =>
      _.assign(_.fill(new Array(length), 100), [...currentValues])
    );
  }, [length]);

  useEffect(() => {
    if (onChange) {
      onChange(values);
    }
  }, [values]);

  const onCellChange = (index, value) => {
    setValues((currentValues) => {
      const newValues = [...currentValues];
      newValues[index] = value;
      return newValues;
    });
  };

  const inputFormatter = getMetricFormatter({
    type: MetricTypes.PERCENTAGE,
    locale,
    currency,
    options: { notation: "standard" }
  });
  const inputParser = getMetricParser({
    type: MetricTypes.PERCENTAGE,
    locale,
    currency
  });

  const onResetPercentages = () => {
    setValues((currentValues) => _.fill(new Array(currentValues.length), 100));
  };

  return (
    <Container>
      {[...Array(length).keys()].map((index) => (
        <InputGroup
          key={index}
          title={new IntlMessageFormat(yearTemplate, "en-US").format({
            year: index + 1
          })}
          size={"medium"}
        >
          <NumberInput
            size={"medium"}
            value={values[index]}
            onChange={(value) => onCellChange(index, value)}
            min={0}
            formatter={inputFormatter}
            parser={inputParser}
            disabled={disabled}
          />
        </InputGroup>
      ))}
      <NewButton
        type={"iconSecondary"}
        onClick={onResetPercentages}
        tooltip={resetTooltip}
      >
        <Icon name={"reset"} />
      </NewButton>
    </Container>
  );
};

const Container = styled.div`
  margin-top: 8px;
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  span {
    font-weight: ${themeProp(`typography.body.fontWeight`)} !important;
  }
`;

export default ListOfPercentageValues;
