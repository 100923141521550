const actionTypes = {
  // Set user info
  USER_INFO_REQUEST: "USER_INFO_REQUEST",
  USER_INFO_SUCCESS: "USER_INFO_SUCCESS",
  USER_INFO_FAILURE: "USER_INFO_FAILURE",

  DISCOVERY_FETCH_REQUEST: "DISCOVERY_FETCH_REQUEST",
  DISCOVERY_FETCH_SUCCESS: "DISCOVERY_FETCH_SUCCESS",
  DISCOVERY_FETCH_FAILURE: "DISCOVERY_FETCH_FAILURE",

  DISCOVERY_SUMMARY_REQUEST: "DISCOVERY_SUMMARY_REQUEST",
  DISCOVERY_SUMMARY_SUCCESS: "DISCOVERY_SUMMARY_SUCCESS",
  DISCOVERY_SUMMARY_FAILURE: "DISCOVERY_SUMMARY_FAILURE",

  DISCOVERY_SAVE_QUESTION_REQUEST: "DISCOVERY_SAVE_QUESTION_REQUEST",
  DISCOVERY_SAVE_QUESTION_SUCCESS: "DISCOVERY_SAVE_QUESTION_SUCCESS",
  DISCOVERY_SAVE_QUESTION_FAILURE: "DISCOVERY_SAVE_QUESTION_FAILURE",

  DISCOVERY_QUESTION_SUBMIT_DISABLED_SUCCESS:
    "DISCOVERY_QUESTION_SUBMIT_DISABLED_SUCCESS",

  DISCOVERY_CANVAS_CLICKED_SUCCESS: "DISCOVERY_CANVAS_CLICKED_SUCCESS",

  DISCOVERY_RESET_REQUEST: "DISCOVERY_RESET_REQUEST",
  DISCOVERY_RESET_SUCCESS: "DISCOVERY_RESET_SUCCESS",
  DISCOVERY_RESET_FAILURE: "DISCOVERY_RESET_FAILURE",

  DISCOVERY_SAVE_CHALLENGE_REQUEST: "DISCOVERY_SAVE_CHALLENGE_REQUEST",
  DISCOVERY_SAVE_CHALLENGE_SUCCESS: "DISCOVERY_SAVE_CHALLENGE_SUCCESS",
  DISCOVERY_SAVE_CHALLENGE_FAILURE: "DISCOVERY_SAVE_CHALLENGE_FAILURE",

  DISCOVERY_REFRESH_REQUEST: "DISCOVERY_REFRESH_REQUEST",
  DISCOVERY_REFRESH_SUCCESS: "DISCOVERY_REFRESH_SUCCESS",
  DISCOVERY_REFRESH_FAILURE: "DISCOVERY_REFRESH_FAILURE",

  DISCOVERY_SAVE_KPI_REQUEST: "DISCOVERY_SAVE_KPI_REQUEST",
  DISCOVERY_SAVE_KPI_SUCCESS: "DISCOVERY_SAVE_KPI_SUCCESS",
  DISCOVERY_SAVE_KPI_FAILURE: "DISCOVERY_SAVE_KPI_FAILURE",

  DISCOVERY_PREVIEW_KPI_REQUEST: "DISCOVERY_PREVIEW_KPI_REQUEST",
  DISCOVERY_PREVIEW_KPI_SUCCESS: "DISCOVERY_PREVIEW_KPI_SUCCESS",
  DISCOVERY_PREVIEW_KPI_FAILURE: "DISCOVERY_PREVIEW_KPI_FAILURE",

  DISCOVERY_COMMIT_PREVIEW_KPI_REQUEST: "DISCOVERY_COMMIT_PREVIEW_KPI_REQUEST",
  DISCOVERY_COMMIT_PREVIEW_KPI_SUCCESS: "DISCOVERY_COMMIT_PREVIEW_KPI_SUCCESS",
  DISCOVERY_COMMIT_PREVIEW_KPI_FAILURE: "DISCOVERY_COMMIT_PREVIEW_KPI_FAILURE",

  DISCOVERY_SAVE_METRIC_REQUEST: "DISCOVERY_SAVE_METRIC_REQUEST",
  DISCOVERY_SAVE_METRIC_SUCCESS: "DISCOVERY_SAVE_METRIC_SUCCESS",
  DISCOVERY_SAVE_METRIC_FAILURE: "DISCOVERY_SAVE_METRIC_FAILURE",

  DISCOVERY_RESET_METRIC_REQUEST: "DISCOVERY_RESET_METRIC_REQUEST",
  DISCOVERY_RESET_METRIC_SUCCESS: "DISCOVERY_RESET_METRIC_SUCCESS",
  DISCOVERY_RESET_METRIC_FAILURE: "DISCOVERY_RESET_METRIC_FAILURE",

  DISCOVERY_SAVE_CHECKPOINT_REQUEST: "DISCOVERY_SAVE_CHECKPOINT_REQUEST",
  DISCOVERY_SAVE_CHECKPOINT_SUCCESS: "DISCOVERY_SAVE_CHECKPOINT_SUCCESS",
  DISCOVERY_SAVE_CHECKPOINT_FAILURE: "DISCOVERY_SAVE_CHECKPOINT_FAILURE",

  DISCOVERY_SAVE_ROI_REQUEST: "DISCOVERY_SAVE_ROI_REQUEST",
  DISCOVERY_SAVE_ROI_SUCCESS: "DISCOVERY_SAVE_ROI_SUCCESS",
  DISCOVERY_SAVE_ROI_FAILURE: "DISCOVERY_SAVE_ROI_FAILURE",

  DISCOVERY_SAVE_SCENARIO_REQUEST: "DISCOVERY_SAVE_SCENARIO_REQUEST",
  DISCOVERY_SAVE_SCENARIO_SUCCESS: "DISCOVERY_SAVE_SCENARIO_SUCCESS",
  DISCOVERY_SAVE_SCENARIO_FAILURE: "DISCOVERY_SAVE_SCENARIO_FAILURE",

  DISCOVERY_SET_SCENARIO_REQUEST: "DISCOVERY_SET_SCENARIO_REQUEST",
  DISCOVERY_SET_SCENARIO_SUCCESS: "DISCOVERY_SET_SCENARIO_SUCCESS",

  DISCOVERY_SAVE_DELAY_TIMELINE_REQUEST:
    "DISCOVERY_SAVE_DELAY_TIMELINE_REQUEST",
  DISCOVERY_SAVE_DELAY_TIMELINE_SUCCESS:
    "DISCOVERY_SAVE_DELAY_TIMELINE_SUCCESS",
  DISCOVERY_SAVE_DELAY_TIMELINE_FAILURE:
    "DISCOVERY_SAVE_DELAY_TIMELINE_FAILURE",

  DISCOVERY_SET_DELAY_TIMELINE_REQUEST: "DISCOVERY_SET_DELAY_TIMELINE_REQUEST",
  DISCOVERY_SET_DELAY_TIMELINE_SUCCESS: "DISCOVERY_SET_DELAY_TIMELINE_SUCCESS",

  DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST:
    "DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_REQUEST",
  DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_SUCCESS:
    "DISCOVERY_SET_SHOW_ADDITIONAL_BENEFITS_SUCCESS",

  DISCOVERY_SAVE_ROI_BENEFITS_REQUEST: "DISCOVERY_SAVE_ROI_BENEFITS_REQUEST",
  DISCOVERY_SAVE_ROI_BENEFITS_SUCCESS: "DISCOVERY_SAVE_ROI_BENEFITS_SUCCESS",
  DISCOVERY_SAVE_ROI_BENEFITS_FAILURE: "DISCOVERY_SAVE_ROI_BENEFITS_FAILURE",

  DISCOVERY_SAVE_SOLUTIONS_REQUEST: "DISCOVERY_SAVE_SOLUTIONS_REQUEST",
  DISCOVERY_SAVE_SOLUTIONS_SUCCESS: "DISCOVERY_SAVE_SOLUTIONS_SUCCESS",
  DISCOVERY_SAVE_SOLUTIONS_FAILURE: "DISCOVERY_SAVE_SOLUTIONS_FAILURE",

  DISCOVERY_PREVIEW_SOLUTIONS_REQUEST: "DISCOVERY_PREVIEW_SOLUTIONS_REQUEST",
  DISCOVERY_PREVIEW_SOLUTIONS_SUCCESS: "DISCOVERY_PREVIEW_SOLUTIONS_SUCCESS",
  DISCOVERY_PREVIEW_SOLUTIONS_FAILURE: "DISCOVERY_PREVIEW_SOLUTIONS_FAILURE",

  DISCOVERY_EXPORT_REQUEST: "DISCOVERY_EXPORT_REQUEST",
  DISCOVERY_EXPORT_SUCCESS: "DISCOVERY_EXPORT_SUCCESS",
  DISCOVERY_EXPORT_FAILURE: "DISCOVERY_EXPORT_FAILURE",

  DISCOVERY_REQUEST_ROI_REQUEST: "DISCOVERY_REQUEST_ROI_REQUEST",
  DISCOVERY_REQUEST_ROI_SUCCESS: "DISCOVERY_REQUEST_ROI_SUCCESS",
  DISCOVERY_REQUEST_ROI_FAILURE: "DISCOVERY_REQUEST_ROI_FAILURE",

  DISCOVERY_REGISTER_INTEREST_REQUEST: "DISCOVERY_REGISTER_INTEREST_REQUEST",
  DISCOVERY_REGISTER_INTEREST_SUCCESS: "DISCOVERY_REGISTER_INTEREST_SUCCESS",
  DISCOVERY_REGISTER_INTEREST_FAILURE: "DISCOVERY_REGISTER_INTEREST_FAILURE",

  DISCOVERY_GENERATE_EMAIL_REQUEST: "DISCOVERY_GENERATE_EMAIL_REQUEST",
  DISCOVERY_GENERATE_EMAIL_SUCCESS: "DISCOVERY_GENERATE_EMAIL_SUCCESS",
  DISCOVERY_GENERATE_EMAIL_FAILURE: "DISCOVERY_GENERATE_EMAIL_FAILURE",

  DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST:
    "DISCOVERY_CALCULATE_KPI_FORMULA_REQUEST",
  DISCOVERY_CALCULATE_KPI_FORMULA_SUCCESS:
    "DISCOVERY_CALCULATE_KPI_FORMULA_SUCCESS",
  DISCOVERY_CALCULATE_KPI_FORMULA_FAILURE:
    "DISCOVERY_CALCULATE_KPI_FORMULA_FAILURE",

  DISCOVERY_UPDATE_SETTINGS_REQUEST: "DISCOVERY_UPDATE_SETTINGS_REQUEST",
  DISCOVERY_UPDATE_SETTINGS_SUCCESS: "DISCOVERY_UPDATE_SETTINGS_SUCCESS",
  DISCOVERY_UPDATE_SETTINGS_FAILURE: "DISCOVERY_UPDATE_SETTINGS_FAILURE",

  DISCOVERY_UPDATE_SETTINGS_GROUP_REQUEST:
    "DISCOVERY_UPDATE_SETTINGS_GROUP_REQUEST",
  DISCOVERY_UPDATE_SETTINGS_GROUP_SUCCESS:
    "DISCOVERY_UPDATE_SETTINGS_GROUP_SUCCESS",
  DISCOVERY_UPDATE_SETTINGS_GROUP_FAILURE:
    "DISCOVERY_UPDATE_SETTINGS_GROUP_FAILURE",

  DISCOVERY_PREVIEW_CASHFLOW_REQUEST: "DISCOVERY_PREVIEW_CASHFLOW_REQUEST",
  DISCOVERY_PREVIEW_CASHFLOW_SUCCESS: "DISCOVERY_PREVIEW_CASHFLOW_SUCCESS",
  DISCOVERY_PREVIEW_CASHFLOW_FAILURE: "DISCOVERY_PREVIEW_CASHFLOW_FAILURE",

  // Notes
  GET_NOTE_REQUEST: "GET_NOTE_REQUEST",
  GET_NOTE_SUCCESS: "GET_NOTE_SUCCESS",
  GET_NOTE_FAILURE: "GET_NOTE_FAILURE",

  UPDATE_NOTE_REQUEST: "UPDATE_NOTE_REQUEST",
  UPDATE_NOTE_SUCCESS: "UPDATE_NOTE_SUCCESS",
  UPDATE_NOTE_FAILURE: "UPDATE_NOTE_FAILURE",

  START_NOTE_SESSION_REQUEST: "START_NOTE_SESSION_REQUEST",
  START_NOTE_SESSION_SUCCESS: "START_NOTE_SESSION_SUCCESS",
  START_NOTE_SESSION_FAILURE: "START_NOTE_SESSION_FAILURE",

  STOP_NOTE_SESSION_REQUEST: "STOP_NOTE_SESSION_REQUEST",
  STOP_NOTE_SESSION_SUCCESS: "STOP_NOTE_SESSION_SUCCESS",
  STOP_NOTE_SESSION_FAILURE: "STOP_NOTE_SESSION_FAILURE",

  KEEP_NOTE_SESSION_ALIVE_REQUEST: "KEEP_NOTE_SESSION_ALIVE_REQUEST",
  KEEP_NOTE_SESSION_ALIVE_SUCCESS: "KEEP_NOTE_SESSION_ALIVE_SUCCESS",
  KEEP_NOTE_SESSION_ALIVE_FAILURE: "KEEP_NOTE_SESSION_ALIVE_FAILURE",

  // Authentication
  INITIAL_LOGIN_COMPLETE: "INITIAL_LOGIN_COMPLETE",
  INITIALIZATION_FAILURE: "INITIALIZATION_FAILURE",

  LOGIN_REQUEST: "LOGIN_REQUEST",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAILURE: "LOGIN_FAILURE",

  LOGOUT_REQUEST: "LOGOUT_REQUEST",
  LOGOUT_FAILURE: "LOGOUT_FAILURE",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",

  TOKEN_REFRESH_REQUEST: "TOKEN_REFRESH_REQUEST",
  TOKEN_REFRESH_SUCCESS: "TOKEN_REFRESH_SUCCESS",
  TOKEN_REFRESH_FAILURE: "TOKEN_REFRESH_FAILURE",

  REGISTER_USER_REQUEST: "REGISTER_USER_REQUEST",
  REGISTER_USER_SUCCESS: "REGISTER_USER_SUCCESS",
  REGISTER_USER_FAILURE: "REGISTER_USER_FAILURE",

  CONFIRM_USER_REQUEST: "CONFIRM_USER_REQUEST",
  CONFIRM_USER_SUCCESS: "CONFIRM_USER_SUCCESS",
  CONFIRM_USER_FAILURE: "CONFIRM_USER_FAILURE",

  RESEND_CONFIRMATION_REQUEST: "RESEND_CONFIRMATION_REQUEST",
  RESEND_CONFIRMATION_SUCCESS: "RESEND_CONFIRMATION_SUCCESS",
  RESEND_CONFIRMATION_FAILURE: "RESEND_CONFIRMATION_FAILURE",

  FORGOT_PASSWORD_REQUEST: "FORGOT_PASSWORD_REQUEST",
  FORGOT_PASSWORD_FAILURE: "FORGOT_PASSWORD_FAILURE",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",

  RESET_PASSWORD_REQUEST: "RESET_PASSWORD_REQUEST",
  RESET_PASSWORD_FAILURE: "RESET_PASSWORD_FAILURE",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",

  // Fetch discoveries
  DISCOVERIES_FETCH_REQUEST: "DISCOVERIES_FETCH_REQUEST",
  DISCOVERIES_FETCH_SUCCESS: "DISCOVERIES_FETCH_SUCCESS",
  DISCOVERIES_FETCH_FAILURE: "DISCOVERIES_FETCH_FAILURE",

  // Add discovery
  ADD_DISCOVERY_REQUEST: "ADD_DISCOVERY_REQUEST",
  ADD_DISCOVERY_SUCCESS: "ADD_DISCOVERY_SUCCESS",
  ADD_DISCOVERY_FAILURE: "ADD_DISCOVERY_FAILURE",

  // Create Sample discoveries
  CREATE_SAMPLE_DISCOVERIES_REQUEST: "CREATE_SAMPLE_DISCOVERIES_REQUEST",
  CREATE_SAMPLE_DISCOVERIES_SUCCESS: "CREATE_SAMPLE_DISCOVERIES_SUCCESS",
  CREATE_SAMPLE_DISCOVERIES_FAILURE: "CREATE_SAMPLE_DISCOVERIES_FAILURE",

  // Update discovery
  UPDATE_DISCOVERY_REQUEST: "UPDATE_DISCOVERY_REQUEST",
  UPDATE_DISCOVERY_SUCCESS: "UPDATE_DISCOVERY_SUCCESS",
  UPDATE_DISCOVERY_FAILURE: "UPDATE_DISCOVERY_FAILURE",

  // Update discovery Canvas properties
  UPDATE_DISCOVERY_CANVAS_REQUEST: "UPDATE_DISCOVERY_CANVAS_REQUEST",
  UPDATE_DISCOVERY_CANVAS_SUCCESS: "UPDATE_DISCOVERY_CANVAS_SUCCESS",
  UPDATE_DISCOVERY_CANVAS_FAILURE: "UPDATE_DISCOVERY_CANVAS_FAILURE",

  // Assign discovery
  ASSIGN_DISCOVERY_REQUEST: "ASSIGN_DISCOVERY_REQUEST",
  ASSIGN_DISCOVERY_SUCCESS: "ASSIGN_DISCOVERY_SUCCESS",
  ASSIGN_DISCOVERY_FAILURE: "ASSIGN_DISCOVERY_FAILURE",

  // Delete discovery
  DELETE_DISCOVERY_REQUEST: "DELETE_DISCOVERY_REQUEST",
  DELETE_DISCOVERY_SUCCESS: "DELETE_DISCOVERY_SUCCESS",
  DELETE_DISCOVERY_FAILURE: "DELETE_DISCOVERY_FAILURE",

  // Get Comment Count
  GET_COMMENT_COUNT_REQUEST: "GET_COMMENT_COUNT_REQUEST",
  GET_COMMENT_COUNT_FAILURE: "GET_COMMENT_COUNT_FAILURE",
  GET_COMMENT_COUNT_SUCCESS: "GET_COMMENT_COUNT_SUCCESS",

  // Get Value Note
  GET_VALUE_NOTE_REQUEST: "GET_VALUE_NOTE_REQUEST",
  GET_VALUE_NOTE_FAILURE: "GET_VALUE_NOTE_FAILURE",
  GET_VALUE_NOTE_SUCCESS: "GET_VALUE_NOTE_SUCCESS",

  // Get Comments
  GET_COMMENTS_REQUEST: "GET_COMMENTS_REQUEST",
  GET_COMMENTS_FAILURE: "GET_COMMENTS_FAILURE",
  GET_COMMENTS_SUCCESS: "GET_COMMENTS_SUCCESS",

  // Post Comment
  POST_COMMENT_REQUEST: "POST_COMMENT_REQUEST",
  POST_COMMENT_FAILURE: "POST_COMMENT_FAILURE",
  POST_COMMENT_SUCCESS: "POST_COMMENT_SUCCESS",

  // Delete Comment
  UPDATE_COMMENT_REQUEST: "UPDATE_COMMENT_REQUEST",
  UPDATE_COMMENT_FAILURE: "UPDATE_COMMENT_FAILURE",
  UPDATE_COMMENT_SUCCESS: "UPDATE_COMMENT_SUCCESS",

  // Delete Comment
  DELETE_COMMENT_REQUEST: "DELETE_COMMENT_REQUEST",
  DELETE_COMMENT_FAILURE: "DELETE_COMMENT_FAILURE",
  DELETE_COMMENT_SUCCESS: "DELETE_COMMENT_SUCCESS",
  SET_DELETING_COMMENT: "SET_DELETING_COMMENT",

  START_EDITING_COMMENT: "START_EDITING_COMMENT",
  STOP_EDITING_COMMENT: "STOP_EDITING_COMMENT",
  CLOSE_COMMENTS: "CLOSE_COMMENTS",

  // KPI Infobox
  DISMISS_DISCOVERY_KPI_INFOBOX: "DISMISS_DISCOVERY_KPI_INFOBOX",
  RESET_DISCOVERY_KPI_INFOBOX: "RESET_DISCOVERY_KPI_INFOBOX",

  // Discovery Operations
  START_DISCOVERY_OPERATION: "START_DISCOVERY_OPERATION",
  STOP_DISCOVERY_OPERATION: "STOP_DISCOVERY_OPERATION",
  CONFIRM_DISCOVERY_OPERATION: "CONFIRM_DISCOVERY_OPERATION",
  ALERT_DISCOVERY_OPERATION: "ALERT_DISCOVERY_OPERATION",

  // Master Data Operations
  GET_MASTER_DATA_REQUEST: "GET_MASTER_DATA_REQUEST",
  GET_MASTER_DATA_SUCCESS: "GET_MASTER_DATA_SUCCESS",
  GET_MASTER_DATA_FAILURE: "GET_MASTER_DATA_FAILURE",

  GET_LATEST_VERSION_REQUEST: "GET_LATEST_VERSION_REQUEST",
  GET_LATEST_VERSION_SUCCESS: "GET_LATEST_VERSION_SUCCESS",
  GET_LATEST_VERSION_FAILURE: "GET_LATEST_VERSION_FAILURE",

  // Users
  USERS_FETCH_REQUEST: "USERS_FETCH_REQUEST",
  USERS_FETCH_SUCCESS: "USERS_FETCH_SUCCESS",
  USERS_FETCH_FAILURE: "USERS_FETCH_FAILURE",

  USERS_DISCOVERY_OWNERS_FETCH_REQUEST: "USERS_DISCOVERY_OWNERS_FETCH_REQUEST",
  USERS_DISCOVERY_OWNERS_FETCH_SUCCESS: "USERS_DISCOVERY_OWNERS_FETCH_SUCCESS",
  USERS_DISCOVERY_OWNERS_FETCH_FAILURE: "USERS_DISCOVERY_OWNERS_FETCH_FAILURE",

  //Access Management User Actions
  USERS_DEACTIVATE_REQUEST: "USERS_DEACTIVATE_REQUEST",
  USERS_DEACTIVATE_SUCCESS: "USERS_DEACTIVATE_SUCCESS",
  USERS_DEACTIVATE_FAILURE: "USERS_DEACTIVATE_FAILURE",

  USERS_REACTIVATE_REQUEST: "USERS_REACTIVATE_REQUEST",
  USERS_REACTIVATE_SUCCESS: "USERS_REACTIVATE_SUCCESS",
  USERS_REACTIVATE_FAILURE: "USERS_REACTIVATE_FAILURE",

  USER_RESET_PASSWORD_REQUEST: "USER_RESET_PASSWORD_REQUEST",
  USER_RESET_PASSWORD_SUCCESS: "USER_RESET_PASSWORD_SUCCESS",
  USER_RESET_PASSWORD_FAILURE: "USER_RESET_PASSWORD_FAILURE",

  // User Settings
  GET_USER_SETTINGS_REQUEST: "GET_USER_SETTINGS_REQUEST",
  GET_USER_SETTINGS_SUCCESS: "GET_USER_SETTINGS_SUCCESS",
  GET_USER_SETTINGS_FAILURE: "GET_USER_SETTINGS_FAILURE",

  SET_USER_SETTINGS_REQUEST: "SET_USER_SETTINGS_REQUEST",
  SET_USER_SETTINGS_SUCCESS: "SET_USER_SETTINGS_SUCCESS",
  SET_USER_SETTINGS_FAILURE: "SET_USER_SETTINGS_FAILURE",

  // Roles
  GET_ROLES_REQUEST: "GET_ROLES_REQUEST",
  GET_ROLES_SUCCESS: "GET_ROLES_SUCCESS",
  GET_ROLES_FAILURE: "GET_ROLES_FAILURE",

  GET_ROLE_REQUEST: "GET_ROLE_REQUEST",
  GET_ROLE_SUCCESS: "GET_ROLE_SUCCESS",
  GET_ROLE_FAILURE: "GET_ROLE_FAILURE",

  GET_ROLE_BY_CODE_REQUEST: "GET_ROLE_BY_CODE_REQUEST",
  GET_ROLE_BY_CODE_SUCCESS: "GET_ROLE_BY_CODE_SUCCESS",
  GET_ROLE_BY_CODE_FAILURE: "GET_ROLE_BY_CODE_FAILURE",

  CREATE_ROLE_REQUEST: "CREATE_ROLE_REQUEST",
  CREATE_ROLE_SUCCESS: "CREATE_ROLE_SUCCESS",
  CREATE_ROLE_FAILURE: "CREATE_ROLE_FAILURE",

  UPDATE_ROLE_REQUEST: "UPDATE_ROLE_REQUEST",
  UPDATE_ROLE_SUCCESS: "UPDATE_ROLE_SUCCESS",
  UPDATE_ROLE_FAILURE: "UPDATE_ROLE_FAILURE",

  DELETE_ROLE_REQUEST: "DELETE_ROLE_REQUEST",
  DELETE_ROLE_SUCCESS: "DELETE_ROLE_SUCCESS",
  DELETE_ROLE_FAILURE: "DELETE_ROLE_FAILURE",

  DUPLICATE_ROLE_REQUEST: "DUPLICATE_ROLE_REQUEST",
  DUPLICATE_ROLE_SUCCESS: "DUPLICATE_ROLE_SUCCESS",
  DUPLICATE_ROLE_FAILURE: "DUPLICATE_ROLE_FAILURE",

  // Create User
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_SUCCESS: "CREATE_USER_SUCCESS",
  CREATE_USER_FAILURE: "CREATE_USER_FAILURE",

  // Update User
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_SUCCESS: "UPDATE_USER_SUCCESS",
  UPDATE_USER_FAILURE: "UPDATE_USER_FAILURE",

  // Access Management Operations
  START_ACCESS_MGMT_OPERATION: "START_ACCESS_MGMT_OPERATION",
  STOP_ACCESS_MGMT_OPERATION: "STOP_ACCESS_MGMT_OPERATION",

  // Edit KPIs
  START_EDITING_KPI: "START_EDITING_KPI",
  STOP_EDITING_KPI: "STOP_EDITING_KPI",
  START_DELETING_KPI: "START_DELETING_KPI",
  STOP_DELETING_KPI: "STOP_DELETING_KPI",

  // Add KPIs
  START_CREATING_KPI: "START_CREATING_KPI",
  STOP_CREATING_KPI: "STOP_CREATING_KPI",
  DISCOVERY_CREATE_KPI_REQUEST: "DISCOVERY_CREATE_KPI_REQUEST",
  DISCOVERY_CREATE_KPI_SUCCESS: "DISCOVERY_CREATE_KPI_SUCCESS",
  DISCOVERY_CREATE_KPI_FAILURE: "DISCOVERY_CREATE_KPI_FAILURE",

  // Edit Challenge
  START_EDITING_CHALLENGE: "START_EDITING_CHALLENGE",
  STOP_EDITING_CHALLENGE: "STOP_EDITING_CHALLENGE",
  START_DELETING_CHALLENGE: "START_DELETING_CHALLENGE",
  STOP_DELETING_CHALLENGE: "STOP_DELETING_CHALLENGE",

  // Add Challenge
  START_CREATING_CHALLENGE: "START_CREATING_CHALLENGE",
  STOP_CREATING_CHALLENGE: "STOP_CREATING_CHALLENGE",
  DISCOVERY_CREATE_CHALLENGE_REQUEST: "DISCOVERY_CREATE_CHALLENGE_REQUEST",
  DISCOVERY_CREATE_CHALLENGE_SUCCESS: "DISCOVERY_CREATE_CHALLENGE_SUCCESS",
  DISCOVERY_CREATE_CHALLENGE_FAILURE: "DISCOVERY_CREATE_CHALLENGE_FAILURE",

  // Notifications
  COUNT_NOTIFICATIONS_REQUEST: "COUNT_NOTIFICATIONS_REQUEST",
  COUNT_NOTIFICATIONS_SUCCESS: "COUNT_NOTIFICATIONS_SUCCESS",
  COUNT_NOTIFICATIONS_FAILURE: "COUNT_NOTIFICATIONS_FAILURE",
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_NOTIFICATIONS_FAILURE: "GET_NOTIFICATIONS_FAILURE",
  GET_DISCOVERY_NOTIFICATIONS_REQUEST: "GET_DISCOVERY_NOTIFICATIONS_REQUEST",
  GET_DISCOVERY_NOTIFICATIONS_SUCCESS: "GET_DISCOVERY_NOTIFICATIONS_SUCCESS",
  GET_DISCOVERY_NOTIFICATIONS_FAILURE: "GET_DISCOVERY_NOTIFICATIONS_FAILURE",
  READ_NOTIFICATIONS_REQUEST: "READ_NOTIFICATIONS_REQUEST",
  READ_NOTIFICATIONS_SUCCESS: "READ_NOTIFICATIONS_SUCCESS",
  READ_NOTIFICATIONS_FAILURE: "READ_NOTIFICATIONS_FAILURE",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",

  // Invitations
  GET_INVITATION_REQUEST: "GET_INVITATION_REQUEST",
  GET_INVITATION_FAILURE: "GET_INVITATION_FAILURE",
  GET_INVITATION_SUCCESS: "GET_INVITATION_SUCCESS",
  GET_DISCOVERY_INVITATIONS_REQUEST: "GET_DISCOVERY_INVITATIONS_REQUEST",
  GET_DISCOVERY_INVITATIONS_SUCCESS: "GET_DISCOVERY_INVITATIONS_SUCCESS",
  GET_DISCOVERY_INVITATIONS_FAILURE: "GET_DISCOVERY_INVITATIONS_FAILURE",
  DELETE_DISCOVERY_INVITATION_REQUEST: "DELETE_DISCOVERY_INVITATION_REQUEST",
  DELETE_DISCOVERY_INVITATION_SUCCESS: "DELETE_DISCOVERY_INVITATION_SUCCESS",
  DELETE_DISCOVERY_INVITATION_FAILURE: "DELETE_DISCOVERY_INVITATION_FAILURE",

  //Share Access
  SHARE_DISCOVERY_ACCESS_REQUEST: "SHARE_DISCOVERY_ACCESS_REQUEST",
  SHARE_DISCOVERY_ACCESS_FAILURE: "SHARE_DISCOVERY_ACCESS_FAILURE",
  SHARE_DISCOVERY_ACCESS_SUCCESS: "SHARE_DISCOVERY_ACCESS_SUCCESS",

  // Files
  CREATE_FILE_REQUEST: "CREATE_FILE_REQUEST",
  CREATE_FILE_SUCCESS: "CREATE_FILE_SUCCESS",
  CREATE_FILE_FAILURE: "CREATE_FILE_FAILURE",

  DOWNLOAD_FILE_REQUEST: "DOWNLOAD_FILE_REQUEST",
  DOWNLOAD_FILE_SUCCESS: "DOWNLOAD_FILE_SUCCESS",
  DOWNLOAD_FILE_FAILURE: "DOWNLOAD_FILE_FAILURE",

  TRANSFORM_AND_DOWNLOAD_FILE_REQUEST: "TRANSFORM_AND_DOWNLOAD_FILE_REQUEST",
  TRANSFORM_AND_DOWNLOAD_FILE_SUCCESS: "TRANSFORM_AND_DOWNLOAD_FILE_SUCCESS",
  TRANSFORM_AND_DOWNLOAD_FILE_FAILURE: "TRANSFORM_AND_DOWNLOAD_FILE_FAILURE",

  // Validations
  CREATE_VALIDATION_REQUEST: "CREATE_VALIDATION_REQUEST",
  CREATE_VALIDATION_SUCCESS: "CREATE_VALIDATION_SUCCESS",
  CREATE_VALIDATION_FAILURE: "CREATE_VALIDATION_FAILURE",

  EXPORT_VALIDATION_REQUEST: "EXPORT_VALIDATION_REQUEST",
  EXPORT_VALIDATION_SUCCESS: "EXPORT_VALIDATION_SUCCESS",
  EXPORT_VALIDATION_FAILURE: "EXPORT_VALIDATION_FAILURE",

  DEPLOY_VALIDATION_REQUEST: "DEPLOY_VALIDATION_REQUEST",
  DEPLOY_VALIDATION_SUCCESS: "DEPLOY_VALIDATION_SUCCESS",
  DEPLOY_VALIDATION_FAILURE: "DEPLOY_VALIDATION_FAILURE",

  // Versions
  GET_VERSION_REQUEST: "GET_VERSION_REQUEST",
  GET_VERSION_SUCCESS: "GET_VERSION_SUCCESS",
  GET_VERSION_FAILURE: "GET_VERSION_FAILURE",

  CONFIG_SUCCESS: "CONFIG_SUCCESS",
  LOGIN_STATE_SUCCESS: "LOGIN_STATE_SUCCESS",

  // Request Access
  REQUEST_ACCESS_REQUEST: "REQUEST_ACCESS_REQUEST",
  REQUEST_ACCESS_FAILURE: "REQUEST_ACCESS_FAILURE",
  REQUEST_ACCESS_SUCCESS: "REQUEST_ACCESS_SUCCESS",

  GET_ACCESS_REQUEST: "GET_ACCESS_REQUEST",
  GET_ACCESS_FAILURE: "GET_ACCESS_FAILURE",
  GET_ACCESS_SUCCESS: "GET_ACCESS_SUCCESS",

  GRANT_ACCESS_REQUEST: "GRANT_ACCESS_REQUEST",
  GRANT_ACCESS_FAILURE: "GRANT_ACCESS_FAILURE",
  GRANT_ACCESS_SUCCESS: "GRANT_ACCESS_SUCCESS",

  IDENTITY_PROVIDER_SUCCESS: "IDENTITY_PROVIDER_SUCCESS",
  SSO_LOGIN_FAILURE: "SSO_LOGIN_FAILURE",

  GET_SALESFORCE_CONFIGURATION_REQUEST: "GET_SALESFORCE_CONFIGURATION_REQUEST",
  GET_SALESFORCE_CONFIGURATION_SUCCESS: "GET_SALESFORCE_CONFIGURATION_SUCCESS",
  GET_SALESFORCE_CONFIGURATION_FAILURE: "GET_SALESFORCE_CONFIGURATION_FAILURE",
  UPDATE_SALESFORCE_CONFIGURATION_REQUEST:
    "UPDATE_SALESFORCE_CONFIGURATION_REQUEST",
  UPDATE_SALESFORCE_CONFIGURATION_SUCCESS:
    "UPDATE_SALESFORCE_CONFIGURATION_SUCCESS",
  UPDATE_SALESFORCE_CONFIGURATION_FAILURE:
    "UPDATE_SALESFORCE_CONFIGURATION_FAILURE",
  DELETE_SALESFORCE_CONFIGURATION_REQUEST:
    "DELETE_SALESFORCE_CONFIGURATION_REQUEST",
  DELETE_SALESFORCE_CONFIGURATION_SUCCESS:
    "DELETE_SALESFORCE_CONFIGURATION_SUCCESS",
  DELETE_SALESFORCE_CONFIGURATION_FAILURE:
    "DELETE_SALESFORCE_CONFIGURATION_FAILURE",

  GET_ADMIN_SETTINGS_REQUEST: "GET_ADMIN_SETTINGS_REQUEST",
  GET_ADMIN_SETTINGS_SUCCESS: "GET_ADMIN_SETTINGS_SUCCESS",
  GET_ADMIN_SETTINGS_FAILURE: "GET_ADMIN_SETTINGS_FAILURE",
  UPDATE_ADMIN_SETTINGS_REQUEST: "UPDATE_ADMIN_SETTINGS_REQUEST",
  UPDATE_ADMIN_SETTINGS_SUCCESS: "UPDATE_ADMIN_SETTINGS_SUCCESS",
  UPDATE_ADMIN_SETTINGS_FAILURE: "UPDATE_ADMIN_SETTINGS_FAILURE",

  SALESFORCE_TOKEN_REQUEST: "SALESFORCE_TOKEN_REQUEST",
  SALESFORCE_TOKEN_SUCCESS: "SALESFORCE_TOKEN_SUCCESS",
  SALESFORCE_TOKEN_FAILURE: "SALESFORCE_TOKEN_FAILURE",

  GET_COOKIE: "GET_COOKIE",
  SET_COOKIE: "SET_COOKIE",

  GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST: "GET_LATEST_CRM_ACCOUNTS_LOG_REQUEST",
  GET_LATEST_CRM_ACCOUNTS_LOG_SUCCESS: "GET_LATEST_CRM_ACCOUNTS_LOG_SUCCESS",
  GET_LATEST_CRM_ACCOUNTS_LOG_FAILURE: "GET_LATEST_CRM_ACCOUNTS_LOG_FAILURE",

  GET_CRM_ACCOUNTS_REQUEST: "GET_CRM_ACCOUNTS_REQUEST",
  GET_CRM_ACCOUNTS_SUCCESS: "GET_CRM_ACCOUNTS_SUCCESS",
  GET_CRM_ACCOUNTS_FAILURE: "GET_CRM_ACCOUNTS_FAILURE",

  GET_CRM_OPPORTUNITIES_REQUEST: "GET_CRM_OPPORTUNITIES_REQUEST",
  GET_CRM_OPPORTUNITIES_SUCCESS: "GET_CRM_OPPORTUNITIES_SUCCESS",
  GET_CRM_OPPORTUNITIES_FAILURE: "GET_CRM_OPPORTUNITIES_FAILURE",

  GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST:
    "GET_DISCOVERY_EXPORT_TEMPLATES_REQUEST",
  GET_DISCOVERY_EXPORT_TEMPLATES_SUCCESS:
    "GET_DISCOVERY_EXPORT_TEMPLATES_SUCCESS",
  GET_DISCOVERY_EXPORT_TEMPLATES_FAILURE:
    "GET_DISCOVERY_EXPORT_TEMPLATES_FAILURE",

  EXPORT_DISCOVERIES_REQUEST: "EXPORT_DISCOVERIES_REQUEST",
  EXPORT_DISCOVERIES_SUCCESS: "EXPORT_DISCOVERIES_SUCCESS",
  EXPORT_DISCOVERIES_FAILURE: "EXPORT_DISCOVERIES_FAILURE",

  UPDATE_DISCOVERY_ENGAGEMENT_COUNTS: "UPDATE_DISCOVERY_ENGAGEMENT_COUNTS",

  DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS:
    "DISCOVERY_SET_REPORT_TEMPLATE_SUCCESS",

  GET_JOB_REQUEST: "GET_JOB_REQUEST",
  GET_JOB_SUCCESS: "GET_JOB_SUCCESS",
  GET_JOB_FAILURE: "GET_JOB_FAILURE",

  GET_NOTES_TEMPLATE_REQUEST: "GET_NOTES_TEMPLATE_REQUEST",
  GET_NOTES_TEMPLATE_SUCCESS: "GET_NOTES_TEMPLATE_SUCCESS",
  GET_NOTES_TEMPLATE_FAILURE: "GET_NOTES_TEMPLATE_FAILURE",

  UPDATE_NOTES_TEMPLATE_REQUEST: "UPDATE_NOTES_TEMPLATE_REQUEST",
  UPDATE_NOTES_TEMPLATE_SUCCESS: "UPDATE_NOTES_TEMPLATE_SUCCESS",
  UPDATE_NOTES_TEMPLATE_FAILURE: "UPDATE_NOTES_TEMPLATE_FAILURE",

  //CHALLENGE LIBRARY
  SET_CHALLENGE_LIBRARY_OPEN: "SET_CHALLENGE_LIBRARY_OPEN",

  GET_CHALLENGE_LIBRARY_REQUEST: "GET_CHALLENGE_LIBRARY_REQUEST",
  GET_CHALLENGE_LIBRARY_SUCCESS: "GET_CHALLENGE_LIBRARY_SUCCESS",
  GET_CHALLENGE_LIBRARY_FAILURE: "GET_CHALLENGE_LIBRARY_FAILURE",

  SET_SCROLL_TO_CHALLENGE_CODE: "SET_SCROLL_TO_CHALLENGE_CODE",
  CLEAR_SCROLL_TO_CHALLENGE_CODE: "CLEAR_SCROLL_TO_CHALLENGE_CODE",

  DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST:
    "DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_REQUEST",
  DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_SUCCESS:
    "DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_SUCCESS",
  DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_FAILURE:
    "DISCOVERY_ADD_CHALLENGE_FROM_LIBRARY_FAILURE",

  DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST:
    "DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_REQUEST",
  DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_SUCCESS:
    "DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_SUCCESS",
  DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_FAILURE:
    "DISCOVERY_REMOVE_CHALLENGE_FROM_DISCOVERY_FAILURE",

  DISCOVERY_DELETE_CHALLENGE_REQUEST: "DISCOVERY_DELETE_CHALLENGE_REQUEST",
  DISCOVERY_DELETE_CHALLENGE_SUCCESS: "DISCOVERY_DELETE_CHALLENGE_SUCCESS",
  DISCOVERY_DELETE_CHALLENGE_FAILURE: "DISCOVERY_DELETE_CHALLENGE_FAILURE",

  DISCOVERY_RESTORE_CHALLENGE_REQUEST: "DISCOVERY_RESTORE_CHALLENGE_REQUEST",
  DISCOVERY_RESTORE_CHALLENGE_SUCCESS: "DISCOVERY_RESTORE_CHALLENGE_SUCCESS",
  DISCOVERY_RESTORE_CHALLENGE_FAILURE: "DISCOVERY_RESTORE_CHALLENGE_FAILURE",

  //KPI LIBRARY
  SET_KPI_LIBRARY_OPEN: "SET_KPI_LIBRARY_OPEN",

  GET_KPI_LIBRARY_REQUEST: "GET_KPI_LIBRARY_REQUEST",
  GET_KPI_LIBRARY_SUCCESS: "GET_KPI_LIBRARY_SUCCESS",
  GET_KPI_LIBRARY_FAILURE: "GET_KPI_LIBRARY_FAILURE",

  SET_SCROLL_TO_KPI_CODE: "SET_SCROLL_TO_KPI_CODE",
  CLEAR_SCROLL_TO_KPI_CODE: "CLEAR_SCROLL_TO_KPI_CODE",

  DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST:
    "DISCOVERY_ADD_KPI_FROM_LIBRARY_REQUEST",
  DISCOVERY_ADD_KPI_FROM_LIBRARY_SUCCESS:
    "DISCOVERY_ADD_KPI_FROM_LIBRARY_SUCCESS",
  DISCOVERY_ADD_KPI_FROM_LIBRARY_FAILURE:
    "DISCOVERY_ADD_KPI_FROM_LIBRARY_FAILURE",

  DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST:
    "DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_REQUEST",
  DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_SUCCESS:
    "DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_SUCCESS",
  DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_FAILURE:
    "DISCOVERY_REMOVE_KPI_FROM_DISCOVERY_FAILURE",

  FREEZE_DISCOVERY_KPIS_REQUEST: "FREEZE_DISCOVERY_KPIS_REQUEST",
  FREEZE_DISCOVERY_KPIS_SUCCESS: "FREEZE_DISCOVERY_KPIS_SUCCESS",
  FREEZE_DISCOVERY_KPIS_FAILURE: "FREEZE_DISCOVERY_KPIS_FAILURE",

  REORDER_DISCOVERY_KPIS_REQUEST: "REORDER_DISCOVERY_KPIS_REQUEST",
  REORDER_DISCOVERY_KPIS_SUCCESS: "REORDER_DISCOVERY_KPIS_SUCCESS",
  REORDER_DISCOVERY_KPIS_FAILURE: "REORDER_DISCOVERY_KPIS_FAILURE",

  REORDER_DISCOVERY_CHALLENGES_REQUEST: "REORDER_DISCOVERY_CHALLENGES_REQUEST",
  REORDER_DISCOVERY_CHALLENGES_SUCCESS: "REORDER_DISCOVERY_CHALLENGES_SUCCESS",
  REORDER_DISCOVERY_CHALLENGES_FAILURE: "REORDER_DISCOVERY_CHALLENGES_FAILURE",

  DISCOVERY_DELETE_KPI_REQUEST: "DISCOVERY_DELETE_KPI_REQUEST",
  DISCOVERY_DELETE_KPI_SUCCESS: "DISCOVERY_DELETE_KPI_SUCCESS",
  DISCOVERY_DELETE_KPI_FAILURE: "DISCOVERY_DELETE_KPI_FAILURE",

  DISCOVERY_RESTORE_KPI_REQUEST: "DISCOVERY_RESTORE_KPI_REQUEST",
  DISCOVERY_RESTORE_KPI_SUCCESS: "DISCOVERY_RESTORE_KPI_SUCCESS",
  DISCOVERY_RESTORE_KPI_FAILURE: "DISCOVERY_RESTORE_KPI_FAILURE",

  COMPANY_RESEARCH_REQUEST: "COMPANY_RESEARCH_REQUEST",
  COMPANY_RESEARCH_SUCCESS: "COMPANY_RESEARCH_SUCCESS",
  COMPANY_RESEARCH_FAILURE: "COMPANY_RESEARCH_FAILURE",

  ADD_FILE_TO_LIST: "ADD_FILE_TO_LIST",
  REMOVE_FILE_FROM_LIST: "REMOVE_FILE_FROM_LIST",
  CLEAR_FILE_LIST: "CLEAR_FILE_LIST",

  UPLOAD_FILE_REQUEST: "UPLOAD_FILE_REQUEST",
  UPLOAD_FILE_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_FILE_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_FILE_PROGRESS: "UPLOAD_FILE_PROGRESS",

  DEPLOY_POWERPOINT_TEMPLATE_REQUEST: "DEPLOY_POWERPOINT_TEMPLATE_REQUEST",
  DEPLOY_POWERPOINT_TEMPLATE_SUCCESS: "DEPLOY_POWERPOINT_TEMPLATE_SUCCESS",
  DEPLOY_POWERPOINT_TEMPLATE_FAILURE: "DEPLOY_POWERPOINT_TEMPLATE_FAILURE",

  GET_THEME_REQUEST: "GET_THEME_REQUEST",
  GET_THEME_SUCCESS: "GET_THEME_SUCCESS",
  GET_THEME_FAILURE: "GET_THEME_FAILURE",

  GET_STRINGS_REQUEST: "GET_STRINGS_REQUEST",
  GET_STRINGS_SUCCESS: "GET_STRINGS_SUCCESS",
  GET_STRINGS_FAILURE: "GET_STRINGS_FAILURE",

  DOWNLOAD_STRINGS_REQUEST: "DOWNLOAD_STRINGS_REQUEST",
  DOWNLOAD_STRINGS_SUCCESS: "DOWNLOAD_STRINGS_SUCCESS",
  DOWNLOAD_STRINGS_FAILURE: "DOWNLOAD_STRINGS_FAILURE",

  GET_CURRENT_CONFIGURATIONS_REQUEST: "GET_CURRENT_CONFIGURATIONS_REQUEST",
  GET_CURRENT_CONFIGURATIONS_SUCCESS: "GET_CURRENT_CONFIGURATIONS_SUCCESS",
  GET_CURRENT_CONFIGURATIONS_FAILURE: "GET_CURRENT_CONFIGURATIONS_FAILURE",

  DOWNLOAD_GUIDE_REQUEST: "DOWNLOAD_GUIDE_REQUEST",
  DOWNLOAD_GUIDE_SUCCESS: "DOWNLOAD_GUIDE_SUCCESS",
  DOWNLOAD_GUIDE_FAILURE: "DOWNLOAD_GUIDE_FAILURE",

  GET_CONFIGURATION_LOG_REQUEST: "GET_CONFIGURATION_LOG_REQUEST",
  GET_CONFIGURATION_LOG_SUCCESS: "GET_CONFIGURATION_LOG_SUCCESS",
  GET_CONFIGURATION_LOG_FAILURE: "GET_CONFIGURATION_LOG_FAILURE",

  GET_CONFIGURATION_LOGS_REQUEST: "GET_CONFIGURATION_LOGS_REQUEST",
  GET_CONFIGURATION_LOGS_SUCCESS: "GET_CONFIGURATION_LOGS_SUCCESS",
  GET_CONFIGURATION_LOGS_FAILURE: "GET_CONFIGURATION_LOGS_FAILURE",

  UPDATE_BRANDING_THEMES_REQUEST: "UPDATE_BRANDING_THEMES_REQUEST",
  UPDATE_BRANDING_THEMES_SUCCESS: "UPDATE_BRANDING_THEMES_SUCCESS",
  UPDATE_BRANDING_THEMES_FAILURE: "UPDATE_BRANDING_THEMES_FAILURE",

  UPDATE_BRANDING_STRINGS_REQUEST: "UPDATE_BRANDING_STRINGS_REQUEST",
  UPDATE_BRANDING_STRINGS_SUCCESS: "UPDATE_BRANDING_STRINGS_SUCCESS",
  UPDATE_BRANDING_STRINGS_FAILURE: "UPDATE_BRANDING_STRINGS_FAILURE",

  OPEN_CONFIGURATION_MODAL: "OPEN_CONFIGURATION_MODAL",
  CLOSE_CONFIGURATION_MODAL: "CLOSE_CONFIGURATION_MODAL",

  DOWNLOAD_ALL_FILE_DEPLOYMENTS_REQUEST:
    "DOWNLOAD_ALL_FILE_DEPLOYMENTS_REQUEST",
  DOWNLOAD_ALL_FILE_DEPLOYMENTS_SUCCESS:
    "DOWNLOAD_ALL_FILE_DEPLOYMENTS_SUCCESS",
  DOWNLOAD_ALL_FILE_DEPLOYMENTS_FAILURE:
    "DOWNLOAD_ALL_FILE_DEPLOYMENTS_FAILURE",

  GET_AUTOCONFIGURATION_REQUEST: "GET_AUTOCONFIGURATION_REQUEST",
  GET_AUTOCONFIGURATION_SUCCESS: "GET_AUTOCONFIGURATION_SUCCESS",
  GET_AUTOCONFIGURATION_FAILURE: "GET_AUTOCONFIGURATION_FAILURE",

  CREATE_AUTOCONFIGURATION_REQUEST: "CREATE_AUTOCONFIGURATION_REQUEST",
  CREATE_AUTOCONFIGURATION_SUCCESS: "CREATE_AUTOCONFIGURATION_SUCCESS",
  CREATE_AUTOCONFIGURATION_FAILURE: "CREATE_AUTOCONFIGURATION_FAILURE",

  GENERATE_AUTOCONFIGURATION_REQUEST: "GENERATE_AUTOCONFIGURATION_REQUEST",
  GENERATE_AUTOCONFIGURATION_SUCCESS: "GENERATE_AUTOCONFIGURATION_SUCCESS",
  GENERATE_AUTOCONFIGURATION_FAILURE: "GENERATE_AUTOCONFIGURATION_FAILURE",

  VALIDATE_AUTOCONFIGURATION_REQUEST: "VALIDATE_AUTOCONFIGURATION_REQUEST",
  VALIDATE_AUTOCONFIGURATION_SUCCESS: "VALIDATE_AUTOCONFIGURATION_SUCCESS",
  VALIDATE_AUTOCONFIGURATION_FAILURE: "VALIDATE_AUTOCONFIGURATION_FAILURE",

  REQUEST_PROMOTION_REQUEST: "REQUEST_PROMOTION_REQUEST",
  REQUEST_PROMOTION_SUCCESS: "REQUEST_PROMOTION_SUCCESS",
  REQUEST_PROMOTION_FAILURE: "REQUEST_PROMOTION_FAILURE",

  REJECT_PROMOTION_REQUEST: "REJECT_PROMOTION_REQUEST",
  REJECT_PROMOTION_SUCCESS: "REJECT_PROMOTION_SUCCESS",
  REJECT_PROMOTION_FAILURE: "REJECT_PROMOTION_FAILURE",

  APPROVE_PROMOTION_REQUEST: "APPROVE_PROMOTION_REQUEST",
  APPROVE_PROMOTION_SUCCESS: "APPROVE_PROMOTION_SUCCESS",
  APPROVE_PROMOTION_FAILURE: "APPROVE_PROMOTION_FAILURE",

  GET_ACTIVE_PROMOTION_REQUEST: "GET_ACTIVE_PROMOTION_REQUEST",
  GET_ACTIVE_PROMOTION_SUCCESS: "GET_ACTIVE_PROMOTION_SUCCESS",
  GET_ACTIVE_PROMOTION_FAILURE: "GET_ACTIVE_PROMOTION_FAILURE",

  SET_FULLSCREEN: "SET_FULLSCREEN",

  NOTIFICATION_MESSAGE_OPEN: "NOTIFICATION_MESSAGE_OPEN",
  NOTIFICATION_MESSAGE_CLOSE: "NOTIFICATION_MESSAGE_CLOSE",

  GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_REQUEST:
    "GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_REQUEST",
  GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_SUCCESS:
    "GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_SUCCESS",
  GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_FAILURE:
    "GET_AUTOCOMPLETE_CHALLENGE_LIBRARY_FAILURE",

  GET_AUTOCOMPLETE_KPI_LIBRARY_REQUEST: "GET_AUTOCOMPLETE_KPI_LIBRARY_REQUEST",
  GET_AUTOCOMPLETE_KPI_LIBRARY_SUCCESS: "GET_AUTOCOMPLETE_KPI_LIBRARY_SUCCESS",
  GET_AUTOCOMPLETE_KPI_LIBRARY_FAILURE: "GET_AUTOCOMPLETE_KPI_LIBRARY_FAILURE",

  GET_GUEST_REQUEST: "GET_GUEST_REQUEST",
  GET_GUEST_SUCCESS: "GET_GUEST_SUCCESS",
  GET_GUEST_FAILURE: "GET_GUEST_FAILURE",

  REGISTER_GUEST_REQUEST: "REGISTER_GUEST_REQUEST",
  REGISTER_GUEST_SUCCESS: "REGISTER_GUEST_SUCCESS",
  REGISTER_GUEST_FAILURE: "REGISTER_GUEST_FAILURE",

  INVITATION_CHECK_EMAIL_STATUS_REQUEST:
    "INVITATION_CHECK_EMAIL_STATUS_REQUEST",
  INVITATION_CHECK_EMAIL_STATUS_SUCCESS:
    "INVITATION_CHECK_EMAIL_STATUS_SUCCESS",
  INVITATION_CHECK_EMAIL_STATUS_FAILURE:
    "INVITATION_CHECK_EMAIL_STATUS_FAILURE",

  UPDATE_MY_INVITATIONS_REQUEST: "UPDATE_MY_INVITATIONS_REQUEST",
  UPDATE_MY_INVITATIONS_SUCCESS: "UPDATE_MY_INVITATIONS_SUCCESS",
  UPDATE_MY_INVITATIONS_FAILURE: "UPDATE_MY_INVITATIONS_FAILURE",

  GET_INTEGRATION_REQUEST: "GET_INTEGRATION_REQUEST",
  GET_INTEGRATION_SUCCESS: "GET_INTEGRATION_SUCCESS",
  GET_INTEGRATION_FAILURE: "GET_INTEGRATION_FAILURE",

  SET_INTEGRATION_REQUEST: "SET_INTEGRATION_REQUEST",
  SET_INTEGRATION_SUCCESS: "SET_INTEGRATION_SUCCESS",
  SET_INTEGRATION_FAILURE: "SET_INTEGRATION_FAILURE",

  DELETE_INTEGRATION_REQUEST: "DELETE_INTEGRATION_REQUEST",
  DELETE_INTEGRATION_SUCCESS: "DELETE_INTEGRATION_SUCCESS",
  DELETE_INTEGRATION_FAILURE: "DELETE_INTEGRATION_FAILURE",

  RETRIEVE_INTEGRATION_TOKEN_REQUEST: "RETRIEVE_INTEGRATION_TOKEN_REQUEST",
  RETRIEVE_INTEGRATION_TOKEN_SUCCESS: "RETRIEVE_INTEGRATION_TOKEN_SUCCESS",
  RETRIEVE_INTEGRATION_TOKEN_FAILURE: "RETRIEVE_INTEGRATION_TOKEN_FAILURE",

  GET_SALESFORCE_INSTALLATION_REQUEST: "GET_SALESFORCE_INSTALLATION_REQUEST",
  GET_SALESFORCE_INSTALLATION_SUCCESS: "GET_SALESFORCE_INSTALLATION_SUCCESS",
  GET_SALESFORCE_INSTALLATION_FAILURE: "GET_SALESFORCE_INSTALLATION_FAILURE",

  START_SALESFORCE_INSTALLATION_REQUEST:
    "START_SALESFORCE_INSTALLATION_REQUEST",
  START_SALESFORCE_INSTALLATION_SUCCESS:
    "START_SALESFORCE_INSTALLATION_SUCCESS",
  START_SALESFORCE_INSTALLATION_FAILURE:
    "START_SALESFORCE_INSTALLATION_FAILURE",

  GET_CONVERSATION_PROMPTS_REQUEST: "GET_CONVERSATION_PROMPTS_REQUEST",
  GET_CONVERSATION_PROMPTS_SUCCESS: "GET_CONVERSATION_PROMPTS_SUCCESS",
  GET_CONVERSATION_PROMPTS_FAILURE: "GET_CONVERSATION_PROMPTS_FAILURE",

  START_CONVERSATION_REQUEST: "START_CONVERSATION_REQUEST",
  START_CONVERSATION_SUCCESS: "START_CONVERSATION_SUCCESS",
  START_CONVERSATION_FAILURE: "START_CONVERSATION_FAILURE",

  GET_CONVERSATION_REQUEST: "GET_CONVERSATION_REQUEST",
  GET_CONVERSATION_SUCCESS: "GET_CONVERSATION_SUCCESS",
  GET_CONVERSATION_FAILURE: "GET_CONVERSATION_FAILURE",

  GET_ALL_CONVERSATIONS_REQUEST: "GET_ALL_CONVERSATIONS_REQUEST",
  GET_ALL_CONVERSATIONS_SUCCESS: "GET_ALL_CONVERSATIONS_SUCCESS",
  GET_ALL_CONVERSATIONS_FAILURE: "GET_ALL_CONVERSATIONS_FAILURE",

  CONTINUE_CONVERSATION_REQUEST: "CONTINUE_CONVERSATION_REQUEST",
  CONTINUE_CONVERSATION_SUCCESS: "CONTINUE_CONVERSATION_SUCCESS",
  CONTINUE_CONVERSATION_FAILURE: "CONTINUE_CONVERSATION_FAILURE",

  RETRY_CONVERSATION_REQUEST: "RETRY_CONVERSATION_REQUEST",
  RETRY_CONVERSATION_SUCCESS: "RETRY_CONVERSATION_SUCCESS",
  RETRY_CONVERSATION_FAILURE: "RETRY_CONVERSATION_FAILURE",

  RESET_DISCOVERY_AI_CONVERSATION_REQUEST:
    "RESET_DISCOVERY_AI_CONVERSATION_REQUEST",
  RESET_DISCOVERY_AI_CONVERSATION_SUCCESS:
    "RESET_DISCOVERY_AI_CONVERSATION_SUCCESS",
  RESET_DISCOVERY_AI_CONVERSATION_FAILURE:
    "RESET_DISCOVERY_AI_CONVERSATION_FAILURE"
};

export default actionTypes;
