import React, { useEffect, useState, useRef } from "react";
import styled, { css } from "styled-components";
import Markdown from "../../Discovery/Markdown";
import formatMarkdownText from "../../../utils/format-markdown-text";
import { themeProp } from "../../../theme";
import AiChatRoleNames from "../../../utils/constants/ai-chat-role-names";
import ChatLoader from "./ChatLoader";
import { useChat } from "./ChatProvider";
import ChatError from "./ChatError";
import ChatControlPanel from "./ChatControlPanel";

const ChatMessage = () => {
  const lastMessageRef = useRef(null);
  const [lastAssistantIndex, setLastAssistantIndex] = useState(null);
  const { chatEndRef, chatData, chatError, chatLoading, scrollToChatBottom } =
    useChat();

  const { messages } = chatData || {};

  useEffect(() => {
    if (messages) {
      setLastAssistantIndex(
        messages.findLastIndex(
          (message) => message.role === AiChatRoleNames.ASSISTANT
        )
      );
    }

    if (chatLoading.active) {
      scrollToChatBottom();
      return;
    }

    lastMessageRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  }, [messages, chatLoading.active]);

  return (
    <ChatMessageContainer data-cy={"chat-messages"}>
      {messages?.map((item, index) =>
        !item?.hidden ? (
          <Message
            key={item.id}
            data-message-id={item.id}
            data-message-author-role={item.role}
            role={item.role}
            ref={index === messages.length - 1 ? lastMessageRef : null}
          >
            <MessageInner role={item.role}>
              <StyledMarkdown paragraphMargin={true}>
                {formatMarkdownText(item.content)}
              </StyledMarkdown>
              <ChatControlPanel
                role={item.role}
                content={item.content}
                isRegenerateActive={index === lastAssistantIndex}
              />
            </MessageInner>
          </Message>
        ) : null
      )}
      {chatLoading.active && <ChatLoader type={chatLoading.type} />}
      {chatError && <ChatError />}
      <ChatScrollBottom ref={chatEndRef} />
    </ChatMessageContainer>
  );
};

const ChatMessageContainer = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Message = styled.div`
  width: 100%;
  display: flex;

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      justify-content: flex-end;
    `}
`;

const MessageInner = styled.article`
  padding: 16px;
  border-radius: 6px;

  ${({ role }) =>
    role === AiChatRoleNames.USER &&
    css`
      background-color: ${themeProp("palette.accent3")};
      max-width: 525px;
    `}

  ${({ role }) =>
    role === AiChatRoleNames.ASSISTANT &&
    css`
      background-color: ${themeProp("palette.surface")};
      border: 1px solid ${themeProp("palette.gray2")};
    `}
`;

const ChatScrollBottom = styled.div`
  margin-top: -20px;
`;

const StyledMarkdown = styled(Markdown)`
  * {
    padding: 0;
    margin: 0;
  }

  h1 {
    font-size: ${themeProp(`typography.bLarge.fontSize`)};
    line-height: ${themeProp(`typography.bLarge.lineHeight`)};
    font-weight: ${themeProp(`typography.bLarge.fontWeight`)};
    font-family: ${themeProp(`typography.bLarge.fontFamily`)};
  }

  h2 {
    font-size: ${themeProp(`typography.bLargeMobile.fontSize`)};
    line-height: ${themeProp(`typography.bLargeMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bLargeMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bLargeMobile.fontFamily`)};
  }

  h3,
  h4,
  h5,
  h6 {
    font-size: ${themeProp(`typography.bMedium.fontSize`)};
    line-height: ${themeProp(`typography.bMedium.lineHeight`)};
    font-weight: ${themeProp(`typography.bMedium.fontWeight`)};
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    padding: 12px 0px;
  }

  p {
    font-size: ${themeProp(`typography.body.fontSize`)};
    line-height: ${themeProp(`typography.body.lineHeight`)};
    font-weight: ${themeProp(`typography.body.fontWeight`)};
    font-family: ${themeProp(`typography.body.fontFamily`)};
  }

  hr {
    margin: 12px 0px;
    border: 0;
    border-top: 1px solid ${themeProp("palette.gray2")};
  }

  ul,
  ol,
  li {
    padding-bottom: 8px;
    list-style-position: outside;
    padding-left: 5px;
  }

  ul {
    list-style-type: disc;
  }

  ol {
    list-style-type: decimal;
  }

  li {
    margin-left: 10px;
  }

  table {
    display: block;
    border-collapse: collapse;
    margin: 12px 0px;
    max-width: 604px;
    overflow-x: scroll;

    @media screen and (max-width: 1013px) {
      max-width: 550px;
    }

    @media screen and (max-width: 960px) {
      max-width: 500px;
    }

    @media screen and (max-width: 908px) {
      max-width: 400px;
    }

    @media screen and (max-width: 806px) {
      max-width: 350px;
    }
  }

  th,
  td {
    border-bottom: 1px solid ${themeProp("palette.gray2")};
    padding: 16px;
    text-align: left;
    font-size: ${themeProp(`typography.bodyMobile.fontSize`)};
    line-height: ${themeProp(`typography.bodyMobile.lineHeight`)};
    font-weight: ${themeProp(`typography.bodyMobile.fontWeight`)};
    font-family: ${themeProp(`typography.bodyMobile.fontFamily`)};
  }

  th {
    font-family: ${themeProp(`typography.bMedium.fontFamily`)};
    color: ${themeProp("palette.gray4")};
    border: none;
    position: relative;

    &:first-child {
      border-top-left-radius: 8px;
    }

    &:last-child {
      border-top-right-radius: 8px;

      &::after {
        display: none;
      }
    }

    &::after {
      content: "";
      background: ${themeProp("palette.gray2")};
      width: 1px;
      height: 23px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  thead {
    background-color: ${themeProp("palette.gray1")};
    border: none;
  }

  code {
    white-space: pre-wrap;
    word-wrap: break-word;
  }

  pre {
    background-color: ${themeProp("palette.gray5")};
    padding: 8px;
    border-radius: 6px;
    margin-bottom: 16px;
  }
`;

export default ChatMessage;
